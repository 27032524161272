import { Objects } from './Objects';

export class Strings {
  public static isEmpty(value: string): boolean {
    return Objects.isDefined(value) && value === '';
  }

  public static concat(strings: Array<string>): string {
    let concatenatedString = '';
    for (const str of strings) {
      concatenatedString = concatenatedString.concat(str);
    }
    return concatenatedString;
  }

  public static containsIgnoreCase(source: string, search: string): boolean {
    return Strings.contains(source.toLowerCase(), search.toLowerCase());
  }

  public static contains(source: string, search: string): boolean {
    return source.indexOf(search) > -1;
  }

  public static removeWhitespaces(source: string): string {
    return source.replace(new RegExp('\\s', 'g'), '');
  }

  public static format(message: string, params: any[] = []): string {
    let i = 0;
    return message.replace(/%s/g, (): string => {
      return params[i++];
    });
  }
}
