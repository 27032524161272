export enum Keymap {
  TAB = 'Tab',
  COMMA = 'Comma',
  CTRL = 'ControlLeft',
  CTRLRIGHT = 'ControlRight',
  SHIFTLEFT = 'ShiftLeft',
  SHIFTRIGHT = 'ShiftRight',
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
  ALTLEFT = 'AltLeft',
  ALTRIGHT = 'AltRight',
  SPACE = ' ',
  OSLEFT = 'OSLeft',
  OSRIGHT = 'OSRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  ESC = 'Escape',
  DEL = 'Delete',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F123'
}
