import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OpenableDirective} from '../../modal-commons/openable.directive';

@Component({
  selector: 'soul-modal',
  templateUrl: 'modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent extends OpenableDirective {

  @Input()
  public title: string;

  @Input()
  public type = 'neutral';

  @Input()
  public size = 'default';

}
