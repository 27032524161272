import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyboardEvent } from '../../utils/KeyboardEvent';
import { Keymap } from '../../utils/Keymap';
import { Events } from '../../utils/Events';

@Component({
  selector: 'soul-searchbox',
  templateUrl: 'searchbox.component.html'
})
export class SearchboxComponent {
  @Input()
  public clearAfterSearch = true;
  @Input()
  public liveSearch = false;
  @Input()
  public placeholder = '';

  @Output()
  public search: EventEmitter<string> = new EventEmitter<string>();

  public searchTerm = '';

  constructor() {
    this.clearSearchTerm();
  }

  public type(keyboardEvent: KeyboardEvent): void {
    if (keyboardEvent.key !== Keymap.ESC) {
      Events.stopEventPropagation(keyboardEvent);
    }
    if (this.canPerformSearch(keyboardEvent)) {
      this.doSearch();
    }
  }

  public doSearch(): void {
    this.search.emit(this.searchTerm);
    if (this.canClearSearchTerm()) {
      this.clearSearchTerm();
    }
  }

  private clearSearchTerm(): void {
    this.searchTerm = '';
  }

  private canPerformSearch(keyboardEvent: KeyboardEvent): boolean {
    return (keyboardEvent.key === Keymap.ENTER || this.liveSearch);
  }

  private canClearSearchTerm(): boolean {
    return !this.liveSearch && this.clearAfterSearch;
  }

}
