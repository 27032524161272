import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[soulAnimationEnd]'
})
export class AnimationEndDirective {

  @Output()
  public animationEnded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('animationend', ['$event'])
  onAnimationEnd($event: Event): void {
    $event.stopPropagation();
    this.animationEnded.emit(true);
  }

}
