import { Box } from '../tree-commons/Box';
import { ItemNavigation } from './ItemNavigation';

export class BoxNavigation extends ItemNavigation {
  public item: Box;
  public name: string;
  public children: ItemNavigation[];
  public selected = false;
  public expanded = false;
}
