import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {NotificationCenter} from '../models/NotificationCenter';
import {Subscription} from 'rxjs';
import {NotificationEvents} from '../models/NotificationEvents';

@Component({
  selector: 'soul-toast-notification-list',
  templateUrl: 'toast-notification-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastNotificationListComponent implements AfterViewInit, OnDestroy {
  @Input()
  public notificationCenter: NotificationCenter;

  @ViewChild('bottomAnchor')
  public bottomAnchor: ElementRef;

  private subscription: Subscription;

  constructor(private readonly detector: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    this.subscription = this.notificationCenter.notificationSubject.subscribe((notificationEvent) => {
      this.detector.detectChanges();

      if (notificationEvent === NotificationEvents.PUSH) {
        this.scrollToBottom();
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private scrollToBottom(): void {
    this.bottomAnchor.nativeElement.scrollIntoView({behavior: 'smooth'});
  }
}
