import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class MarkDownFileService {

  constructor(public http: HttpClient) {
  }

  public getContentFile(url: string): Promise<any> {
    return this.http.get(url, {responseType: 'text'}).toPromise()
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('error', error);
    return Promise.reject(error.message || error);
  }

}
