import { PipeTransform } from '@angular/core';
import { Objects } from '../utils/Objects';
import { Item } from '../tree-commons/Item';

export class Column {
  public name: any;
  public title: string;
  public filter: PipeTransform;
  public filterArgs: any;
  public type: string;
  public hidden: boolean;
  public template: any;
  public weightFunction: (...obj: any[]) => any = this.hasValue;

  private _sortPath: string[];

  public get sortPath(): string[] {
    return Objects.isNotNull(this._sortPath) ? this._sortPath : [this.name];
  }

  public set sortPath(path: string[]) {
    this._sortPath = path;
  }

  private hasValue(item: Item, property: string[]): boolean {
    return Objects.isDefined(item[property[0]]);
  }
}
