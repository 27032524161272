import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[soulStickToElement]',
  host: {
    '[style.position]': 'position',
    '[style.top]': 'top',
    '[style.left]': 'left',
    '[style.width]': 'width'
  }
})
export class StickToElementDirective implements AfterViewInit {

  @Input()
  public targetSelector: string;

  public position = 'fixed';
  public top: string;
  public left: string;
  public width: string;

  private readonly nativeElement: HTMLElement;

  private targetElement: HTMLElement;

  constructor(private readonly element: ElementRef, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.nativeElement = this.element.nativeElement;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.stickToElement(), 0);
  }

  public stickToElement(): void {
    this.targetElement = this.nativeElement.ownerDocument.querySelector(this.targetSelector);
    this.setTop();
    this.setLeft();
    this.setWidth();
    this.changeDetectorRef.markForCheck();
  }

  public setTop(): void {
    this.top = (this.targetElement.getBoundingClientRect().top + this.targetElement.offsetHeight) + 'px';
  }

  public setLeft(): void {
    this.left = (this.targetElement.getBoundingClientRect().left) + 'px';
  }

  public setWidth(): void {
    this.width = this.targetElement.offsetWidth + 'px';
  }

}
