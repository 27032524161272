export enum TableSizes {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl'
}

export namespace TableSizes {
  export function getValues(): TableSizes[] {
    return [TableSizes.XS, TableSizes.S, TableSizes.M, TableSizes.L, TableSizes.XL];
  }

  export function getClassForSize(size: TableSizes): string {
    return 'c-table--' + size;
  }
}
