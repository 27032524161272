import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemNavigation } from '../ItemNavigation';
import { Item } from '../../tree-commons/Item';
import { BoxNavigation } from '../BoxNavigation';

@Component({
  selector: 'soul-tree-navigation',
  templateUrl: 'tree-navigation.component.html'
})
export class TreeNavigationComponent {

  @Input()
  public label: string;
  @Input()
  public boxesOnly: boolean;
  @Input()
  public rootNodesExpanded: boolean[] = [];
  @Input()
  public items: Item[];
  @Input()
  public selection: Item;

  @Output()
  public boxSelect: EventEmitter<BoxNavigation> = new EventEmitter<BoxNavigation>();
  @Output()
  public boxExpand: EventEmitter<BoxNavigation> = new EventEmitter<BoxNavigation>();
  @Output()
  public itemSelect: EventEmitter<ItemNavigation> = new EventEmitter<ItemNavigation>();

}
