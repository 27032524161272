import {BehaviorSubject} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Objects} from '../utils/Objects';
import {EventEmitter} from '@angular/core';

export class SelectOptions {

  private static readonly NO_HIGHLIGHT_INDEX = -1;

  public items: BehaviorSubject<any[]>;
  public itemHeight: number;
  public optionsHeight: number;
  public initializingHeights: boolean;
  public heightsInitialized: EventEmitter<void>;
  private optionsMaxHeight: number;
  private opened = false;
  private selectedItem: any;
  private highlightedIndex = SelectOptions.NO_HIGHLIGHT_INDEX;

  constructor(private sourceItems: any[], selectedItemIndex: number) {
    this.items = new BehaviorSubject(sourceItems);
    this.selectedItem = this.sourceItems[selectedItemIndex];
    this.heightsInitialized = new EventEmitter();
  }

  public updateItems(newSourceItems: any[]): void {
    this.sourceItems = newSourceItems;
    this.items.next(this.sourceItems);
  }

  public updateSelectedItem(newSelectedItemIndex: number): void {
    this.selectedItem = this.sourceItems[newSelectedItemIndex];
  }

  public setItemHeight(height: number): void {
    this.itemHeight = height;
    this.emitHeightsInitialization();
  }

  public setOptionsMaxHeight(maxHeight: number): void {
    this.optionsMaxHeight = maxHeight;
    this.emitHeightsInitialization();
  }

  public computeOptionsHeight(): void {
    const allItemsHeight: number = this.itemHeight * this.items.value.length;
    this.optionsHeight = allItemsHeight < this.optionsMaxHeight ? allItemsHeight : this.optionsMaxHeight;
  }

  public filter(filterFunction: (source: any, filterTerm: string) => boolean, filterTerm: string): void {
    this.items.next(this.sourceItems.filter(item => filterFunction(item, filterTerm)));
    this.computeOptionsHeight();
    this.resetHighlight();
  }

  public open(): void {
    if (this.canComputeOptionsHeight()) {
      this.items.next(this.sourceItems);
      this.computeOptionsHeight();
      this.opened = true;
    } else {
      this.initializingHeights = true;
    }
  }

  public close(): void {
    this.opened = false;
    this.resetHighlight();
  }

  public isOpen(): boolean {
    return this.opened && Objects.isDefined(this.optionsHeight) && Objects.isDefined(this.itemHeight);
  }

  public selectItem(item: any): void {
    this.selectedItem = item;
  }

  public getSelectedItem(): any {
    return this.selectedItem;
  }

  public highlightItem(itemIndex: number): void {
    this.highlightedIndex = itemIndex;
  }

  public isItemHighlighted(itemIndex: number): boolean {
    return itemIndex === this.highlightedIndex;
  }

  public selectHighlighted(): void {
    this.selectedItem = this.items.value[this.highlightedIndex];
    this.close();
  }

  public highlightNext(viewport: CdkVirtualScrollViewport): void {
    this.highlightedIndex = this.highlightedIndex < this.items.value.length - 1 ? this.highlightedIndex + 1 : this.highlightedIndex;
    this.scrollDown(viewport);
  }

  public highlightPrevious(viewport: CdkVirtualScrollViewport): void {
    this.highlightedIndex = this.highlightedIndex < 1 ? 0 : this.highlightedIndex - 1;
    this.scrollUp(viewport);
  }

  public hasHighlight(): boolean {
    return this.highlightedIndex !== SelectOptions.NO_HIGHLIGHT_INDEX;
  }

  public isEmpty(): boolean {
    return !this.items.getValue().length;
  }

  private emitHeightsInitialization(): void {
    if (this.canComputeOptionsHeight()) {
      this.heightsInitialized.emit();
    }
  }

  private canComputeOptionsHeight(): boolean {
    return Objects.isDefined(this.itemHeight) && Objects.isDefined(this.optionsMaxHeight);
  }

  private resetHighlight(): void {
    this.highlightedIndex = SelectOptions.NO_HIGHLIGHT_INDEX;
  }

  private scrollUp(viewport: CdkVirtualScrollViewport): void {
    const scrollOffset: number = viewport.measureScrollOffset();
    const firstVisibleIndex: number = Math.ceil(scrollOffset / this.itemHeight);

    if (this.highlightedIndex < firstVisibleIndex) {
      viewport.scrollToIndex(this.highlightedIndex);
    }
  }

  private scrollDown(viewport: CdkVirtualScrollViewport): void {
    const optionsListTopOffset: number = viewport.measureScrollOffset();
    const optionsListBottomOffset: number = optionsListTopOffset + this.optionsHeight;
    const highlightedBottomOffset: number = (this.highlightedIndex + 1) * this.itemHeight;
    const highlightedTopOffset: number = highlightedBottomOffset - this.itemHeight;

    if (!this.isItemCompletelyVisible(optionsListTopOffset, optionsListBottomOffset, highlightedTopOffset, highlightedBottomOffset)) {
      const newOffset: number = optionsListTopOffset + (highlightedBottomOffset - optionsListBottomOffset);
      viewport.scrollToOffset(newOffset);
    }
  }

  private isItemCompletelyVisible(optionsListTopOffset: number, optionsListBottomOffset: number, highlightedTopOffset: number, highlightedBottomOffset: number): boolean {
    return highlightedTopOffset >= optionsListTopOffset && highlightedBottomOffset <= optionsListBottomOffset;
  }
}
