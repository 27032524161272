import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationOutcome } from '../../utils/validators/ValidationOutcome';
import { ValidationOutcomeFactory } from '../../utils/validators/ValidationOutcomeFactory';
import { Objects } from '../../utils/Objects';
import { KeyboardEvent } from '../../utils/KeyboardEvent';
import { Events } from '../../utils/Events';
import { Keymap } from '../../utils/Keymap';

@Component({
  selector: 'soul-textarea',
  templateUrl: 'textarea.component.html'
})
export class TextAreaComponent {
  @Input()
  public label: string;
  @Input()
  public placeholder: string;
  @Input()
  public maxLength = 1024;
  @Input()
  public validateFunction: (value: string) => ValidationOutcome;
  @Input()
  public value: string;
  @Input()
  public subtle: boolean;
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public validationOutcome: ValidationOutcome;

  constructor() {
    this.validationOutcome = new ValidationOutcomeFactory().createValidOutcome();
    this.validateFunction = (value: string): ValidationOutcome => {
      return new ValidationOutcomeFactory().createValidOutcome();
    };
  }

  public validate(): void {
    if (Objects.isDefined(this.validateFunction)) {
      this.validationOutcome = this.validateFunction(this.value);
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key !== Keymap.ESC) {
      Events.stopEventPropagation(event);
    }
    this.valueChange.emit(this.value);
  }

  public remainingCharacters(): number {
    return this.value ? (this.maxLength - this.value.length) : this.maxLength;
  }

}
