import {Explorable} from '../../tree-commons/Explorable';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Output
} from '@angular/core';
import {Objects} from '../../utils/Objects';
import {Column} from '../Column';
import {Item} from '../../tree-commons/Item';
import {Box} from '../../tree-commons/Box';
import {Arrays} from '../../utils/Arrays';
import {SortState} from '../SortState';
import {Interfaces} from '../../tree-commons/Interfaces';
import {Sort} from '../../column-header/Sort';
import {Strings} from '../../utils/Strings';
import {Sticker} from '../Sticker';

@Component({
  selector: 'soul-box-content',
  templateUrl: 'box-content.component.html'
})
export class BoxContentComponent implements OnInit, DoCheck {
  private static readonly DEFAULT_ICON_CLASS = 'a-icon--%s';
  private static readonly COLUMN_WIDTH_STEP = 5;
  private static readonly COLUMN_MAX_WIDTH = 100;

  @Input()
  public columns: Column[];
  @Input()
  public id: Column;
  @Input()
  public stickers: Sticker[];
  @Input()
  public selected: Item;
  @Input()
  public items: Item[];
  @Input()
  public explorable: Explorable;
  @Input()
  public initialSortColumn: Sort;

  @Output()
  public boxSelect: EventEmitter<Box> = new EventEmitter<Box>();
  @Output()
  public boxExplore: EventEmitter<Box> = new EventEmitter<Box>();
  @Output()
  public itemSelect: EventEmitter<Item> = new EventEmitter<Item>();
  @Output()
  public itemExplore: EventEmitter<Item> = new EventEmitter<Item>();

  public model: Item[];
  public selectionModel: boolean[];

  private _collectionDiffer: IterableDiffer<any>;
  private _currentSort: Sort;
  private _objectDiffer: KeyValueDiffer<any, any>;

  constructor(private readonly iterDiffers: IterableDiffers, private readonly keyValueDiffers: KeyValueDiffers) {
  }

  public ngOnInit(): void {
    this._collectionDiffer = this.iterDiffers.find(this.items).create();
    this._objectDiffer = this.getDefaultObjectDiffer();
    if (Objects.isNotDefined(this.initialSortColumn)) {
      this._currentSort = new Sort(this.id, SortState.NONE);
    } else {
      this._currentSort = this.initialSortColumn;
    }
  }

  public ngDoCheck(): void {
    const changes: any = this._collectionDiffer.diff(this.items);
    const selectionChanges: any = this._objectDiffer.diff((this.selected));
    if (changes && Objects.isDefined(this.items)) {
      this.model = Arrays.cloneArray(this.items);
      this.sort(this._currentSort);
    }
    if ((selectionChanges || changes) && Objects.isDefined(this.items)) {
      this.updateSelectionModel();
    }
  }

  public canExplore(item: Item): boolean {
    return this.explorable ? this.explorable.canExplore(item) : this.isBox(item);
  }

  public explore(item: Item): void {
    if (this.canExplore(item)) {
      this.isBox(item) ? this.boxExplore.emit(item as Box) : this.itemExplore.emit(item);
    }
  }

  public select(item: Item, i: number): void {
    if (this.isBox(item)) {
      this.boxSelect.emit(item as Box);
    } else {
      this.itemSelect.emit(item);
    }
    this.selected = item;
    this.updateSelectionModel();
  }

  public sort(sort: Sort): void {
    switch (sort.sortState) {
      case SortState.NONE: {
        Arrays.sortByFunction(this.model, this.isBox, [this.id.name]);
        break;
      }
      case SortState.ASCENDING: {
        Arrays.sortByFunction(this.model, sort.column.weightFunction, sort.column.sortPath);
        break;
      }
      case SortState.DESCENDING: {
        Arrays.descendingSortByFunction(this.model, sort.column.weightFunction, sort.column.sortPath);
        break;
      }
      default: {
        break;
      }
    }
    this._currentSort = sort;
  }

  public nextSort(sort: Sort): void {
    this.sort(sort);
  }

  public getColumnState(column: Column): SortState {
    if (this._currentSort.column === column) {
      return this._currentSort.sortState;
    }
    return SortState.NONE;
  }

  public getIconClass(item: Item): string {
    return item.customIconClass ? item.customIconClass : Strings.format(BoxContentComponent.DEFAULT_ICON_CLASS, [item.type]);
  }

  public isBox(item: Item): boolean {
    return Interfaces.implements(item, Interfaces.BOX);
  }

  public getColumnWidth(): number {
    return Math.floor((BoxContentComponent.COLUMN_MAX_WIDTH / this.columns.length) / BoxContentComponent.COLUMN_WIDTH_STEP) * BoxContentComponent.COLUMN_WIDTH_STEP;
  }

  private updateSelectionModel(): void {
    if (Objects.isDefined(this.model)) {
      this.selectionModel = this.model.map((item: Item): boolean => {
        return item === this.selected;
      });
    } else {
      this.selectionModel = this.items.map((item: Item): boolean => {
        return item === this.selected;
      });
    }
  }

  private getDefaultObjectDiffer(): KeyValueDiffer<any, any> {
    return this.keyValueDiffers.find({}).create();
  }
}
