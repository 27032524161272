import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'soul-click-catcher',
  templateUrl: 'click-catcher.component.html'
})
export class ClickCatcherComponent {
  @Input()
  public target: string;
  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public emitClickEvent(): void {
    this.onClick.emit(null);
  }
}
