import { SortState } from '../box-content/SortState';
import { Column } from '../box-content/Column';

export class Sort {

  public column: Column;
  public sortState: SortState;

  constructor(column: Column, sortState: SortState) {
    this.column = column;
    this.sortState = sortState;
  }
}
