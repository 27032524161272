import { Item } from '../tree-commons/Item';

export class Sticker {
  public type: string;
  public attach: (item: Item) => boolean;

  constructor(type: string, attach: (item: Item) => boolean) {
    this.type = type;
    this.attach = attach;
  }
}
