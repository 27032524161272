export const horizontalScroll: (event: WheelEvent, element: HTMLElement) => void = ($event: WheelEvent, element: HTMLElement): void => {
  $event.preventDefault();
  const deltaScroll = getDeltaScroll($event);
  element.scrollBy({
    behavior: 'auto',
    left: $event.deltaMode ? deltaScroll * element.offsetHeight : deltaScroll,
    top: 0
  });
};

function getDeltaScroll(event: WheelEvent): number {
  return event.deltaX !== 0 ? event.deltaX : event.deltaY;
}
