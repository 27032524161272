import { EmbeddedViewRef, OnChanges, SimpleChange, TemplateRef, ViewContainerRef, Directive } from '@angular/core';

@Directive()
export abstract class TemplateWrapperDirective implements OnChanges {

  protected _embeddedViewRef: EmbeddedViewRef<any>;
  protected _viewContainer: ViewContainerRef;

  constructor(viewContainer: ViewContainerRef) {
    this._viewContainer = viewContainer;
  }

  public ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    if (changes[this.getTemplateWrapperName()]) {
      if (this._embeddedViewRef) {
        this._embeddedViewRef.destroy();
      }

      this._embeddedViewRef = this._viewContainer.createEmbeddedView(
        this.getTemplateWrapper(), this.createEmbeddedViewContext());
    }

    if (this._embeddedViewRef) {
      this.setEmbeddedViewRefValues();
    }
  }

  protected abstract createEmbeddedViewContext(): any;

  protected abstract getTemplateWrapper(): TemplateRef<any>;

  protected abstract setEmbeddedViewRefValues(): void;

  protected abstract getTemplateWrapperName(): string;

}
