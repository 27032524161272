export class SortState {
  public static NONE: SortState = new SortState(0, 'none');
  public static ASCENDING: SortState = new SortState(1, 'ascending');
  public static DESCENDING: SortState = new SortState(2, 'descending');

  public name: string;

  private readonly ordinal: number;

  private static values(): SortState[] {
    return [
      SortState.NONE,
      SortState.ASCENDING,
      SortState.DESCENDING
    ];
  }

  constructor(ordinal: number, name: string) {
    this.ordinal = ordinal;
    this.name = name;
  }

  public next(): SortState {
    const newIndex: number = (this.ordinal + 1) % SortState.values().length;
    return SortState.values()[newIndex];
  }
}
