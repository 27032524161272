import {Directive, DoCheck, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[soulHiddenOverride]'
})
export class HiddenOverrideDirective implements DoCheck {

  @Input()
  public soulHiddenOverride: boolean;

  private readonly element: HTMLElement;
  private readonly previousDisplay: string;

  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
    this.previousDisplay = this.element.style.display;
  }

  public ngDoCheck(): void {
    if (this.soulHiddenOverride) {
      this.element.style.display = 'none';
    } else {
      this.element.style.display = this.previousDisplay;
    }
  }

}
