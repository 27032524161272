import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {ItemNavigation} from '../ItemNavigation';
import {Item} from '../../tree-commons/Item';
import {Objects} from '../../utils/Objects';
import {BoxNavigation} from '../BoxNavigation';
import {Arrays} from '../../utils/Arrays';
import {Interfaces} from '../../tree-commons/Interfaces';
import {Box} from '../../tree-commons/Box';
import {Strings} from '../../models';

@Component({
  selector: 'soul-tree',
  templateUrl: 'tree.component.html',
  host: {
    'class': 'soul-tree soul-tree--compact',
  }
})
export class TreeComponent implements OnInit, OnChanges, DoCheck {
  private static readonly DEFAULT_ICON_CLASS = 'a-icon--%s';

  public model: ItemNavigation[] = [];
  @Input()
  public label: string;
  @Input()
  public boxesOnly: boolean;
  @Input()
  public rootNodesExpanded: boolean[] = [];
  @Input()
  public items: Item[];
  @Input()
  public selection: Item;
  @Input()
  @HostBinding('class.soul-tree--root')
  public isRoot;
  @Input()
  @HostBinding('class.soul-tree--subtree')
  public isSubtree;

  @Output()
  public boxSelect: EventEmitter<BoxNavigation> = new EventEmitter<BoxNavigation>();
  @Output()
  public boxExpand: EventEmitter<BoxNavigation> = new EventEmitter<BoxNavigation>();
  @Output()
  public itemSelect: EventEmitter<ItemNavigation> = new EventEmitter<ItemNavigation>();

  private differ: IterableDiffer<any>;

  constructor(private readonly iterDiffers: IterableDiffers) {
  }

  public ngOnInit(): void {
    this.differ = this.iterDiffers.find(this.items).create();
    if (Objects.isNotNull(this.items)) {
      this.buildModel(this.items);
    }
  }

  public ngDoCheck(): void {
    const changes: any = this.differ.diff(this.items);
    if (changes && Objects.isDefined(this.items)) {
      this.buildModel(this.items);
    }
  }

  public ngOnChanges(): void {
    this.updateModel();
  }

  public select(nav: ItemNavigation): void {
    this.selection = nav.item;
    if (this.isBoxNavigation(nav)) {
      this.boxSelect.emit(nav as BoxNavigation);
    } else {
      this.itemSelect.emit(nav);
    }
  }

  public toggleExpansion(box: BoxNavigation): void {
    box.expanded = !box.expanded;
    this.dispatchExpansionEvent(box);
  }

  public onBoxSelect(box: BoxNavigation): void {
    this.boxSelect.emit(box);
  }

  public onBoxExpand(box: BoxNavigation): void {
    this.boxExpand.emit(box);
  }

  public onItemSelect(item: ItemNavigation): void {
    this.itemSelect.emit(item);
  }

  public isBoxNavigation(itemNavigation: ItemNavigation): boolean {
    return itemNavigation instanceof BoxNavigation;
  }

  public getIconClass(item: Item): string {
    return item.customIconClass ? item.customIconClass : Strings.format(TreeComponent.DEFAULT_ICON_CLASS, [item.type]);
  }

  private dispatchExpansionEvent(box: BoxNavigation): void {
    if (box.expanded) {
      this.boxExpand.emit(box);
    }
  }

  private updateModel(): void {
    if (Objects.isNotDefined(this.model) || Objects.isNotDefined(this.selection)) {
      return;
    }
    this.model.forEach((nav: ItemNavigation) => {
      nav.selected = nav.item === this.selection;
      if (nav instanceof BoxNavigation) {
        const boxNav = nav;
        if (this.contains(boxNav.item.children, this.selection)) {
          boxNav.expanded = true;
        }
      }
    });
  }

  private buildModel(items: Item[]): void {
    Arrays.clearArray(this.model);
    items.forEach((item: Item, index: number) => {
      if (Interfaces.implements(item, Interfaces.BOX)) {
        const nav = new BoxNavigation();
        const box = item as Box;
        nav.item = box;
        nav[this.label] = item[this.label];
        nav.children = [];
        nav.selected = Objects.isDefined(this.selection) && box === this.selection;
        nav.expanded = (Objects.isDefined(this.selection) && this.contains(box.children, this.selection)) || this.rootNodesExpanded[index];
        this.model.push(nav);
      } else {
        const nav: ItemNavigation = new ItemNavigation();
        nav.item = item;
        nav[this.label] = item[this.label];
        nav.selected = Objects.isDefined(this.selection) && item === this.selection;
        this.model.push(nav);
      }
    });

    Arrays.sortByFunction(this.model, this.isBoxNavigation, [this.label]);
  }

  private contains(ancestors: Item[], child: Item): boolean {
    for (const current of ancestors) {
      if (current === child) {
        return true;
      }
      if (Interfaces.implements(current, Interfaces.BOX) && this.contains((current as Box).children, child)) {
        return true;
      }
    }
    return false;
  }
}
