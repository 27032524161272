import { Objects } from './Objects';

declare var naturalSort: any;

export class Arrays {
  public static clearArray(array: Array<any>): void {
    while (array.length) {
      array.pop();
    }
  }

  public static copyArray(sourceArray: Array<any>, targetArray: Array<any>): void {
    if (targetArray.length > 0) {
      this.clearArray(targetArray);
    }
    for (const item of sourceArray) {
      targetArray.push(item);
    }
  }

  public static cloneArray(sourceArray: Array<any>): Array<any> {
    const clonedArray: Array<any> = [];

    this.copyArray(sourceArray, clonedArray);

    return clonedArray;
  }

  public static naturalSortBy(arrayToSort: Array<any>, path: string[], caseInsensitive = false): void {
    arrayToSort.sort((a: any, b: any) => {
      naturalSort.insensitive = caseInsensitive;
      for (const prop of path) {
        a = a[prop];
        b = b[prop];
      }
      return naturalSort(a, b);
    });
  }

  public static descendingNaturalSortBy(arrayToSort: Array<any>, path: string[], caseInsensitive = false): void {
    arrayToSort.sort((a: any, b: any) => {
      naturalSort.insensitive = caseInsensitive;
      for (const prop of path) {
        a = a[prop];
        b = b[prop];
      }
      return naturalSort(b, a);
    });
  }

  public static sortByFunction(arrayToSort: Array<any>, weightFunction: (...obj: any[]) => any, path: string[]): void {
    arrayToSort.sort((a: any, b: any) => {
      if (weightFunction(b, path) > weightFunction(a, path)) {
        return 1;
      }
      if (weightFunction(b, path) < weightFunction(a, path)) {
        return -1;
      }
      naturalSort.insensitive = true;
      for (const prop of path) {
        a = a[prop];
        b = b[prop];
      }
      return naturalSort(a, b);
    });
  }

  public static descendingSortByFunction(arrayToSort: Array<any>, weightFunction: (...obj: any[]) => any, path: string[]): void {
    arrayToSort.sort((a: any, b: any) => {
      if (weightFunction(b, path) > weightFunction(a, path)) {
        return 1;
      }
      if (weightFunction(b, path) < weightFunction(a, path)) {
        return -1;
      }
      naturalSort.insensitive = true;
      for (const prop of path) {
        a = a[prop];
        b = b[prop];
      }
      return naturalSort(b, a);
    });
  }

  public static removeElement(sourceArray: Array<any>, element: any): void {
    if (sourceArray.length === 0) {
      return;
    }
    for (let i: number = sourceArray.length - 1; i >= 0; i -= 1) {
      if (sourceArray[i] === element) {
        sourceArray.splice(i, 1);
      }
    }
  }

  public static removeElementAtIndex(source: Array<any>, index: number): void {
    source.splice(index, 1);
  }

  public static contains(sourceArray: Array<any>, element: any): boolean {
    return sourceArray.indexOf(element) !== -1;
  }

  public static isEmpty(sourceArray: Array<any>): boolean {
    return Objects.isNull(sourceArray) || Objects.isNotDefined(sourceArray) || sourceArray.length === 0;
  }

}
