import {ModalStatus} from './ModalStatus';
import { EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, Directive } from '@angular/core';
import {Objects} from '../utils/Objects';

@Directive()
export abstract class OpenableDirective implements OnChanges {

  @Input()
  public isOpen: boolean;
  public status = ModalStatus.CLOSED;
  @Output()
  public closeEnded = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isChangingStatus(changes.isOpen)) {
      this.status = changes.isOpen.currentValue ? ModalStatus.OPENING : ModalStatus.CLOSING;
    }
  }

  public isClosed(): boolean {
    return this.status === ModalStatus.CLOSED;
  }

  public onAnimationEnd(): void {
    if (this.isModalInTransitionStatus()) {
      this.updateModalStatus();
    }
  }

  private isModalInTransitionStatus(): boolean {
    return this.status === ModalStatus.OPENING || this.status === ModalStatus.CLOSING;
  }

  private updateModalStatus(): void {
    this.status = this.status === ModalStatus.OPENING ? ModalStatus.OPEN : ModalStatus.CLOSED;
    if (this.status === ModalStatus.CLOSED) {
      this.closeEnded.next();
    }
  }

  private isChangingStatus(change: SimpleChange) {
    return Objects.isDefined(change) && !this.isDefaultStatus(change);
  }

  private isDefaultStatus(change: SimpleChange) {
    return change.isFirstChange() && !change.currentValue;
  }
}
