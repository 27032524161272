import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Keymap} from '../../utils/Keymap';
import {SelectEvent} from '../SelectEvent';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SelectOptions} from '../SelectOptions';
import {Subscription} from 'rxjs';

export const SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true
};

@Component({
  selector: 'soul-select',
  templateUrl: 'select.component.html',
  providers: [SELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements AfterContentInit, OnChanges, ControlValueAccessor {

  @Input()
  public label: string;
  @Input()
  public readonly: boolean;
  @Input()
  public stateless: boolean;
  @Input()
  public disabled: boolean;
  @Input()
  public searchable: boolean;
  @Input()
  public placeholder = '';
  @Input()
  public items: any[];
  @Input()
  public selectedIndex: number;
  @Input()
  public maxHeight = '300px';
  @Input()
  public subtle: boolean;
  @Input()
  public filter: (source: any, filterTerm: string) => boolean;
  @Input()
  public disableSpaces = false;

  @Output()
  public itemSelect: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(TemplateRef)
  public itemTemplate: TemplateRef<any>;
  @ViewChild('optionsRef')
  public optionsRef: ElementRef;
  @ViewChild('virtualViewport')
  public virtualViewport: CdkVirtualScrollViewport;

  public options: SelectOptions;
  public filterTerm = '';
  public instanceId: number;

  private propagateChange: (_: any) => {};
  private heightsSubscription: Subscription;

  constructor(public changeDetector: ChangeDetectorRef) {
    this.instanceId = Math.floor(Math.random() * 999);
  }

  public ngAfterContentInit(): void {
    this.options = new SelectOptions(this.items, this.selectedIndex);
    this.heightsSubscription = this.options.heightsInitialized.subscribe(() => {
      this.options.open();
      this.changeDetector.detectChanges();
      this.heightsSubscription.unsubscribe();
    });
  }

  public ngOnChanges(): void {
    if (this.options) {
      this.options.updateItems(this.items);
      this.options.updateSelectedItem(this.selectedIndex);
    }
    this.resetFilterTerm();
  }

  public getPlaceholder(): string {
    return this.selectedIndex < 0 || this.disabled || this.stateless ? this.placeholder : '';
  }

  public isSearchable(): string {
    return this.searchable ? 'true' : null;
  }

  public isDisabled(): string {
    return this.disabled ? 'true' : null;
  }

  public onOptionClick(event: Event): void {
    this.select();
    event.stopPropagation();
  }

  public onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case Keymap.SPACE: {
        this.onSpaceKeyDown(event);
        break;
      }
      case Keymap.DOWN: {
        this.openOrElse(() => {
          this.options.highlightNext(this.virtualViewport);
        });
        event.preventDefault();
        break;
      }
      case Keymap.UP: {
        this.options.highlightPrevious(this.virtualViewport);
        event.preventDefault();
        break;
      }
      case Keymap.ENTER: {
        this.openOrElse(() => this.select());
        break;
      }
      case Keymap.ESC: {
        this.options.close();
        break;
      }
      default:
        break;
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public onType(keyboardEvent: KeyboardEvent): void {
    const selectEvent: SelectEvent = new SelectEvent(keyboardEvent);
    if (!selectEvent.isAction()) {
      this.options.open();
      if (this.searchable) {
        this.options.filter(this.filter, this.filterTerm);
      }
    }
  }

  public writeValue(item: any): void {
    this.options.selectItem(item);
    this.changeDetector.detectChanges();
  }

  public registerOnChange(propagateChange: (_: any) => {}): void {
    this.propagateChange = propagateChange;
  }

  public registerOnTouched(propagateTouched: (_: any) => {}): void {
  }

  public hasFilter(): boolean {
    return this.filterTerm.length !== 0;
  }

  public close(): void {
    this.options.close();
    this.resetFilterTerm();
  }

  private select(): void {
    if (this.canSelect()) {
      this.options.selectHighlighted();
      this.resetFilterTerm();
      this.itemSelect.emit(this.options.getSelectedItem());
      if (this.propagateChange) {
        this.propagateChange(this.options.getSelectedItem());
      }
    } else {
      this.noSelectAction();
    }
  }

  private canSelect() {
    return this.options.hasHighlight();
  }

  public noSelectAction() {
    this.close();
  }

  private resetFilterTerm(): void {
    this.filterTerm = '';
  }

  private onSpaceKeyDown(event: KeyboardEvent) {
    if (this.canOpen()) {
      this.options.open();
      event.preventDefault();
    } else if (!this.searchable || this.disableSpaces) {
      event.preventDefault();
    }
  }

  private openOrElse(elseFunction: Function): any {
    if (this.canOpen()) {
      this.options.open();
    } else {
      elseFunction();
    }
  }

  private canOpen(): boolean {
    return !this.options.isEmpty() && !this.options.isOpen();
  }

}
