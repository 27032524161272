import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import {TableSizes} from './TableSizes';
import {TableBreakpoint} from './TableBreakpoint';
import {Objects} from '../../utils/Objects';
import {ResizeObserverFactory} from '../../utils/resize-observer/ResizeObserverFactory';

@Directive({
  selector: '[soulTableSizes]'
})
export class TableSizesDirective implements AfterViewInit, OnDestroy, OnChanges {

  @Input()
  public xs: number;
  @Input()
  public s: number;
  @Input()
  public m: number;
  @Input()
  public l: number;
  @Input()
  public xl: number;

  private resizeObserver: ResizeObserver;
  private currentSize: TableSizes;
  private breakpoints: TableBreakpoint[] = [];

  constructor(private readonly renderer: Renderer2,
              private readonly hostElement: ElementRef,
              private readonly resizeObserverFactory: ResizeObserverFactory) {
  }

  public ngAfterViewInit(): void {
    this.setBreakpoints();
    this.observe();
  }

  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  public ngOnChanges(): void {
    this.setBreakpoints();
    this.checkSize(this.hostElement.nativeElement.parentElement.clientWidth);
  }

  public getBreakpoints(): TableBreakpoint[] {
    return this.breakpoints;
  }

  private observe(): void {
    this.resizeObserver = this.resizeObserverFactory.create((entries: ResizeObserverEntry[]) => this.checkSize(entries[0].contentRect.width));
    this.resizeObserver.observe(this.hostElement.nativeElement.parentElement);
  }

  private checkSize(width: number): void {
    const newSize: TableSizes = this.breakpoints.reduce((currentSize: TableSizes, breakpoint: TableBreakpoint) => {
      return (width <= breakpoint.width) ? breakpoint.size : currentSize;
    }, undefined);

    this.updateSizeClass(newSize);
  }

  private updateSizeClass(newSize: any): void {
    if (newSize !== this.currentSize) {
      this.removeSizeClass();
      this.currentSize = newSize;
      this.addSizeClass();
    }
  }

  private addSizeClass(): void {
    if (Objects.isDefined(this.currentSize)) {
      this.renderer.addClass(this.hostElement.nativeElement, TableSizes.getClassForSize(this.currentSize));
    }
  }

  private removeSizeClass(): void {
    this.renderer.removeClass(this.hostElement.nativeElement, TableSizes.getClassForSize(this.currentSize));
  }

  private setBreakpoints(): void {
    const sizes: TableSizes[] = TableSizes.getValues();
    this.breakpoints = this.buildDescendingOrderBreakpoints(sizes);
  }

  private buildDescendingOrderBreakpoints(sizes: TableSizes[]): TableBreakpoint[] {
    return [this.xs, this.s, this.m, this.l, this.xl]
      .map((width: number, index: number) => {
        return {size: sizes[index], width};
      })
      .filter(size => !isNaN(size.width))
      .reverse();
  }
}
