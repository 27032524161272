import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'soul-expander',
  templateUrl: 'expander.component.html'
})
export class ExpanderComponent {
  @Input()
  public expanded: boolean;
  @Input()
  public highlight = false;
  @Input()
  public transparent = false;
  @Output()
  public onExpand: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public collapse: EventEmitter<any> = new EventEmitter<any>();

  public toggleExpander(): void {
    this.emitToggleEvent();
    this.expanded = !this.expanded;
  }

  private emitToggleEvent(): void {
    if (!this.expanded) {
      this.onExpand.emit(null);
    } else {
      this.collapse.emit(null);
    }
  }
}
