import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'soulTranslate',
  pure: false
})
export class SoulTranslatePipe implements PipeTransform, OnDestroy {

  public translatePipe: TranslatePipe;

  constructor(private readonly translateService: TranslateService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.translatePipe = new TranslatePipe(this.translateService, this.changeDetectorRef);
  }

  public transform(key: string): string {
    const translation: any = this.translatePipe.transform(key);
    if (typeof translation === 'string') {
      return translation;
    }
    return key;
  }

  public ngOnDestroy(): any {
    this.translatePipe.ngOnDestroy();
  }
}
