import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {Converter} from 'showdown';
import {Objects} from '../../utils/Objects';
import {Strings} from '../../utils/Strings';

import {MarkDownFileService} from '../services/markdown-file.service';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'soul-markdown-display',
  templateUrl: 'markdown-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownDisplayComponent implements OnChanges {

  @Input()
  public value: string;
  @Input()
  public url: string;
  @Input()
  public zoom = 's';
  @Input()
  public trustContent = false;
  @Output()
  public converted: EventEmitter<any> = new EventEmitter();

  public convertedValue: string | SafeHtml;

  private converter: Converter;

  constructor(public markDownFileService: MarkDownFileService, private readonly changeDetectorRef: ChangeDetectorRef, private readonly safeHtmlPipe: SafeHtmlPipe) {
    this.converter = new Converter();
    this.converter.setOption('strikethrough', 'true');
    this.converter.setOption('tables', 'true');
    this.converter.setOption('tasklists', 'true');
    this.converter.setOption('simplifiedAutoLink', 'true');
  }

  public ngOnChanges(): void {
    if (Objects.isDefined(this.url) && !Strings.isEmpty(this.url)) {
      this.markDownFileService.getContentFile(this.url).then((data: string) => {
        this.convertedValue = this.getHtml(data);
        this.changeDetectorRef.detectChanges();
        this.converted.emit();
      });
    } else {
      this.convertedValue = this.getHtml(this.value);
      this.converted.emit();
    }
  }

  private getHtml(markdown: string): SafeHtml {
    const html: string = this.convert(markdown);
    return this.trustContent ? this.safeHtmlPipe.transform(html) : html;
  }

  private convert(markdown: string): string {
    return this.converter.makeHtml(markdown);
  }

}
