import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[soulScrollOutside]'
})
export class ScrollOutsideDirective implements OnInit, OnDestroy {

  @Output()
  public outsideScroll: EventEmitter<any> = new EventEmitter<any>();

  private readonly nativeElement: HTMLElement;

  constructor(private readonly element: ElementRef) {
    this.nativeElement = this.element.nativeElement;
  }

  public ngOnInit(): void {
    window.addEventListener('scroll', (event) => this.onScroll(event), true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', (event) => this.onScroll(event));
  }

  public onScroll(event: Event): void {
    if (!this.nativeElement.contains(event.target as Node)) {
      this.outsideScroll.emit(event);
    }
  }
}
