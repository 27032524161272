import ResizeObserver from 'resize-observer-polyfill';
import { Injectable } from '@angular/core';

@Injectable()
export class ResizeObserverFactory {

  public create(callback: ResizeObserverCallback): ResizeObserver {
    return new ResizeObserver(callback);
  }

}
