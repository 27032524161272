import {Notification} from './Notification';
import {NotificationSeverities} from './NotificationSeverities';
import {ComponentRef, TemplateRef} from '@angular/core';

export class InfoNotification implements Notification {
  public title: string;
  public message: string;
  public dismissible: boolean;
  public severity: NotificationSeverities;
  public template: TemplateRef<any>;
  public component: ComponentRef<any>;

  constructor(title: string) {
    this.title = title;
    this.dismissible = true;
    this.severity = NotificationSeverities.INFO;
  }
}
