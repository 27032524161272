import {Keymap} from '../utils/Keymap';
import {Arrays} from '../utils/Arrays';

export class SelectEvent {

  private static readonly ACTION_EVENTS: Keymap[] = [
    Keymap.SPACE,
    Keymap.DOWN,
    Keymap.UP,
    Keymap.ENTER,
    Keymap.TAB,
    Keymap.SHIFTLEFT,
    Keymap.SHIFTRIGHT,
    Keymap.ESC
  ];

  constructor(private readonly keyboardEvent: KeyboardEvent) {
  }

  public isAction(): boolean {
    return Arrays.contains(SelectEvent.ACTION_EVENTS, this.keyboardEvent.key);
  }

}
