import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import {NotificationCenter} from '../../toast-notification-list/models/NotificationCenter';
import {Notification} from '../models/Notification';
import {Objects} from '../../utils/Objects';

@Component({
  selector: 'soul-toast-notification',
  templateUrl: 'toast-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastNotificationComponent implements AfterViewInit {

  @Input()
  public notification: Notification;

  @Input()
  public notificationCenter: NotificationCenter;

  @ViewChild('componentContainer')
  componentContainer: ElementRef;

  constructor(private readonly renderer: Renderer2) {
  }

  public ngAfterViewInit(): void {
    if (this.notification.component) {
      this.renderer.appendChild(
        this.componentContainer.nativeElement,
        this.notification.component.location.nativeElement
      );
    }
  }

  public get showMessage(): boolean {
    return Objects.isNotDefined(this.notification.template) && Objects.isNotDefined(this.notification.component);
  }

  public get showTemplate(): boolean {
    return Objects.isDefined(this.notification.template) && Objects.isNotDefined(this.notification.component);
  }

  public hasContent(): boolean {
    return Objects.isDefined(this.notification.message) || Objects.isDefined(this.notification.component) || Objects.isDefined(this.notification.template);
  }
}
