import {AfterViewInit, Directive, EventEmitter, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[soulHeight]'
})
export class SoulHeightDirective implements AfterViewInit {

  @Output()
  public height: EventEmitter<number> = new EventEmitter();

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  public ngAfterViewInit(): void {
    const elementRect: ClientRect = this.viewContainerRef.element.nativeElement.getBoundingClientRect();
    this.height.emit(elementRect.bottom - elementRect.top);
  }
}
