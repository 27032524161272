import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Objects} from '../../utils/Objects';

@Component({
  selector: 'soul-avatar',
  templateUrl: 'avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input()
  public chars: string;
  @Input()
  public mainText: string;
  @Input()
  public type = 'user';
  @Input()
  public subtleText: string;
  @Input()
  public status: string;
  @Input()
  public size = 'm';
  @Input()
  public icon: string;
  @Input()
  public iconSize: 'm';
  @Input()
  public imageUrl: string;
  @Input()
  public counter = false;
  @Input()
  public hideContent = false;
  @Input()
  public color: number;

  private readonly colorClass = 'soul-avatar--color-';
  private readonly availableColors = 13;

  public get showChars(): boolean {
    return !this.showImage && !this.showIcon;
  }

  public get showIcon(): boolean {
    return Objects.isNotNull(this.icon) && !this.showImage;
  }

  public get showImage(): boolean {
    return Objects.isNotNull(this.imageUrl);
  }

  public get showStatus(): boolean {
    return Objects.isNotNull(this.status) && !this.counter;
  }

  public get showContent(): boolean {
    return !this.hideContent && (Objects.isNotNull(this.mainText) || Objects.isNotNull(this.subtleText));
  }

  public get letters(): string {
    let letters = this.chars ? this.chars : this.getLettersFromContent();
    if (this.size === 's') {
      letters = letters.substr(0, 1);
    } else {
      letters = letters.substr(0, 2);
    }
    return letters.toUpperCase();
  }

  public get avatarColorClass(): string {
    return Objects.isNotNull(this.color) ?
      this.colorClass + this.getColorIndexFromNumber(this.color) :
      this.letters.length !== 0 ? this.getColorClassFromLetters() : '';
  }

  public getLettersFromContent(): string {
    return this.mainText ? this.getInitials(this.mainText) : this.getLettersFromSubtleText();
  }

  private getColorIndexFromNumber(input: number): number {
    return input % this.availableColors;
  }

  private getInitials(text: string): string {
    const splitText = text.split(' ').map(token => token.substr(0, 1));
    return splitText.length > 1 ? splitText[0].concat(splitText[1]) : text.substr(0, 2);
  }

  private getColorClassFromLetters(): string {
    const firstCharCode = this.letters.charCodeAt(0);
    return this.colorClass + this.getColorIndexFromNumber(firstCharCode);
  }

  private getLettersFromSubtleText(): string {
    return this.subtleText ? this.getInitials(this.subtleText) : '';
  }
}
