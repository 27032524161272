import {Notification} from '../../toast-notification/models/Notification';
import {Arrays} from '../../utils/Arrays';
import {BehaviorSubject, Subject} from 'rxjs';
import {NotificationEvents} from './NotificationEvents';

export class NotificationCenter {
  public notifications: Array<Notification>;
  public notificationLifetimeInMilliseconds: number;
  public notificationSubject: Subject<NotificationEvents> = new BehaviorSubject<NotificationEvents>(null);

  constructor() {
    this.notifications = [];
    this.notificationLifetimeInMilliseconds = 3000;
  }

  public pushNotification(notification: Notification): void {
    this.notifications.push(notification);
    this.notificationSubject.next(NotificationEvents.PUSH);
  }

  public pullNotification(notification: Notification): void {
    Arrays.removeElement(this.notifications, notification);
    this.notificationSubject.next(NotificationEvents.PULL);
  }

  public pushTransientNotification(notification: Notification): void {
    this.pushNotification(notification);
    this.setInfoNotificationRemoval(notification);
  }

  private setInfoNotificationRemoval(notification: Notification): void {
    const notificationToBeRemoved: Notification = notification;
    setTimeout(
      () => {
        this.pullNotification(notificationToBeRemoved);
      },
      this.notificationLifetimeInMilliseconds
    );
  }
}
