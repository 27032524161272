import { Component, Input } from '@angular/core';

@Component({
  selector: 'soul-empty-state',
  templateUrl: 'empty-state.component.html'
})
export class EmptyStateComponent {
  @Input()
  public title: string;
  @Input()
  public description: string;
  @Input()
  public state: string;
}
