import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[soulBackgroundImage]',
  host: {
    '[style.background-image]': 'getValue()'
  }
})
export class BackgroundImageDirective {

  @Input()
  public soulBackgroundImage: string;

  public getValue(): string {
    return `url("${this.soulBackgroundImage}")`;
  }
}
