export class Interfaces {

  public static BOX: string[] = ['isBox'];

  public static implements(obj: any, interfaceProps: string[]): boolean {
    const objectKeys: string[] = Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).concat(Object.getOwnPropertyNames(obj));
    return interfaceProps.every((interfaceProp: string) => {
      return objectKeys.indexOf(interfaceProp) !== -1;
    });
  }
}
