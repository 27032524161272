import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'soul-spinner',
  templateUrl: 'spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input()
  public size = 'small';
}
