import {WarningNotification} from './WarningNotification';
import {CriticalNotification} from './CriticalNotification';
import {InfoNotification} from './InfoNotification';
import {Notification} from './Notification';
import {ComponentRef, TemplateRef} from '@angular/core';
import {NotificationSeverities} from './NotificationSeverities';
import {Objects} from '../../utils/Objects';
import {PositiveNotification} from './PositiveNotification';
import {WaitingNotification} from './WaitingNotification';

export class NotificationBuilder {

  private readonly type: NotificationSeverities;
  private readonly title: string;
  private message: string;
  private dismissible: boolean;
  private template: TemplateRef<any>;
  private component: ComponentRef<any>;

  constructor(type: NotificationSeverities, title: string) {
    this.type = type;
    this.title = title;
  }

  public setDismissible(dismissible: boolean): NotificationBuilder {
    this.dismissible = dismissible;
    return this;
  }

  public setMessage(message: string): NotificationBuilder {
    this.message = message;
    return this;
  }

  public setTemplate(template: TemplateRef<any>): NotificationBuilder {
    this.template = template;
    return this;
  }

  public setComponent(component: ComponentRef<any>): NotificationBuilder {
    this.component = component;
    return this;
  }

  public build(): Notification {
    let notification: Notification;
    switch (this.type) {
      case NotificationSeverities.POSITIVE:
        notification = new PositiveNotification(this.title);
        break;
      case NotificationSeverities.CRITICAL:
        notification = new CriticalNotification(this.title);
        break;
      case NotificationSeverities.WARNING:
        notification = new WarningNotification(this.title);
        break;
      case NotificationSeverities.INFO:
        notification = new InfoNotification(this.title);
        break;
      case NotificationSeverities.WAITING:
        notification = new WaitingNotification(this.title);
        break;
    }

    if (Objects.isDefined(this.dismissible)) {
      notification.dismissible = this.dismissible;
    }
    if (Objects.isDefined(this.message)) {
      notification.message = this.message;
    }
    if (Objects.isDefined(this.component)) {
      notification.component = this.component;
    }
    if (Objects.isDefined(this.template)) {
      notification.template = this.template;
    }

    return notification;
  }
}
