import {ValidationOutcome} from './ValidationOutcome';

export class ValidationOutcomeFactory {

  public createValidOutcome(): ValidationOutcome {
    return new ValidationOutcome('', true);
  }

  public createInvalidOutcome(message: string): ValidationOutcome {
    return new ValidationOutcome(message, false);
  }
}
