import { AfterViewInit, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[soulSelectContent]'
})
export class SelectContentDirective implements AfterViewInit {

  @Input()
  public itemTemplate: TemplateRef<any>;
  @Input()
  public item: any;

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  public ngAfterViewInit(): void {
    const view: EmbeddedViewRef<any> = this.viewContainerRef.createEmbeddedView(this.itemTemplate, {item: this.item});
    view.context.item = this.item;
  }
}
