export class ColorTokens {
    public static SOUL_THEME_COLOR_BASE = 'hsl(210, 22%, 49%)';
    public static SOUL_THEME_COLOR_HOVER = 'hsl(209, 28%, 39%)';
    public static SOUL_THEME_COLOR_TEXT_ON_LIGHT = 'hsl(0, 0%, 23%)';
    public static SOUL_THEME_COLOR_TEXT_STRONG_ON_LIGHT = 'hsl(0, 0%, 13%)';
    public static SOUL_THEME_COLOR_TEXT_LINK = 'hsl(209, 28%, 39%)';
    public static SOUL_THEME_COLOR_PRIMARY_50 = 'hsl(210, 36%, 96%)';
    public static SOUL_THEME_COLOR_PRIMARY_100 = 'hsl(212, 33%, 89%)';
    public static SOUL_THEME_COLOR_PRIMARY_200 = 'hsl(210, 31%, 80%)';
    public static SOUL_THEME_COLOR_PRIMARY_300 = 'hsl(211, 27%, 70%)';
    public static SOUL_THEME_COLOR_PRIMARY_400 = 'hsl(209, 23%, 60%)';
    public static SOUL_THEME_COLOR_PRIMARY_500 = 'hsl(210, 22%, 49%)';
    public static SOUL_THEME_COLOR_PRIMARY_600 = 'hsl(209, 28%, 39%)';
    public static SOUL_THEME_COLOR_PRIMARY_700 = 'hsl(209, 34%, 30%)';
    public static SOUL_THEME_COLOR_PRIMARY_800 = 'hsl(211, 39%, 23%)';
    public static SOUL_THEME_COLOR_PRIMARY_900 = 'hsl(209, 61%, 16%)';
    public static SOUL_THEME_COLOR_SECONDARY_50 = 'hsl(201, 100%, 94%)';
    public static SOUL_THEME_COLOR_SECONDARY_100 = 'hsl(202, 67%, 88%)';
    public static SOUL_THEME_COLOR_SECONDARY_200 = 'hsl(203, 65%, 78%)';
    public static SOUL_THEME_COLOR_SECONDARY_300 = 'hsl(203, 65%, 69%)';
    public static SOUL_THEME_COLOR_SECONDARY_400 = 'hsl(204, 64%, 58%)';
    public static SOUL_THEME_COLOR_SECONDARY_500 = 'hsl(204, 58%, 49%)';
    public static SOUL_THEME_COLOR_SECONDARY_600 = 'hsl(204, 67%, 41%)';
    public static SOUL_THEME_COLOR_SECONDARY_700 = 'hsl(204, 65%, 31%)';
    public static SOUL_THEME_COLOR_SECONDARY_800 = 'hsl(204, 60%, 20%)';
    public static SOUL_THEME_COLOR_SECONDARY_900 = 'hsl(204, 54%, 15%)';
    public static SOUL_THEME_COLOR_NEUTRAL_50 = 'hsl(0, 0%, 97%)';
    public static SOUL_THEME_COLOR_NEUTRAL_100 = 'hsl(0, 0%, 88%)';
    public static SOUL_THEME_COLOR_NEUTRAL_200 = 'hsl(0, 0%, 81%)';
    public static SOUL_THEME_COLOR_NEUTRAL_300 = 'hsl(0, 0%, 69%)';
    public static SOUL_THEME_COLOR_NEUTRAL_400 = 'hsl(0, 0%, 62%)';
    public static SOUL_THEME_COLOR_NEUTRAL_500 = 'hsl(0, 0%, 49%)';
    public static SOUL_THEME_COLOR_NEUTRAL_600 = 'hsl(0, 0%, 38%)';
    public static SOUL_THEME_COLOR_NEUTRAL_700 = 'hsl(0, 0%, 32%)';
    public static SOUL_THEME_COLOR_NEUTRAL_800 = 'hsl(0, 0%, 23%)';
    public static SOUL_THEME_COLOR_NEUTRAL_900 = 'hsl(0, 0%, 13%)';
    public static SOUL_CHOICE_COLOR_BLACK = 'hsl(0, 0%, 0%)';
    public static SOUL_CHOICE_COLOR_WHITE = 'hsl(0, 0%, 100%)';
    public static SOUL_CHOICE_COLOR_TRANSPARENT = 'hsla(0, 0%, 100%, 0)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_50 = 'hsl(210, 36%, 96%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_100 = 'hsl(212, 33%, 89%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_200 = 'hsl(210, 31%, 80%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_300 = 'hsl(211, 27%, 70%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_400 = 'hsl(209, 23%, 60%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_500 = 'hsl(210, 22%, 49%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_600 = 'hsl(209, 28%, 39%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_700 = 'hsl(209, 34%, 30%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_800 = 'hsl(211, 39%, 23%)';
    public static SOUL_CHOICE_COLOR_BLUE_GREY_900 = 'hsl(209, 61%, 16%)';
    public static SOUL_CHOICE_COLOR_BLUE_50 = 'hsl(205, 79%, 92%)';
    public static SOUL_CHOICE_COLOR_BLUE_100 = 'hsl(205, 97%, 85%)';
    public static SOUL_CHOICE_COLOR_BLUE_200 = 'hsl(205, 84%, 74%)';
    public static SOUL_CHOICE_COLOR_BLUE_300 = 'hsl(205, 74%, 65%)';
    public static SOUL_CHOICE_COLOR_BLUE_400 = 'hsl(205, 65%, 55%)';
    public static SOUL_CHOICE_COLOR_BLUE_500 = 'hsl(205, 67%, 45%)';
    public static SOUL_CHOICE_COLOR_BLUE_600 = 'hsl(205, 76%, 39%)';
    public static SOUL_CHOICE_COLOR_BLUE_700 = 'hsl(205, 82%, 33%)';
    public static SOUL_CHOICE_COLOR_BLUE_800 = 'hsl(205, 87%, 29%)';
    public static SOUL_CHOICE_COLOR_BLUE_900 = 'hsl(205, 100%, 21%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_50 = 'hsl(216, 33%, 97%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_100 = 'hsl(214, 15%, 91%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_200 = 'hsl(210, 16%, 82%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_300 = 'hsl(211, 13%, 65%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_400 = 'hsl(211, 10%, 53%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_500 = 'hsl(211, 12%, 43%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_600 = 'hsl(209, 14%, 37%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_700 = 'hsl(209, 18%, 30%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_800 = 'hsl(209, 20%, 25%)';
    public static SOUL_CHOICE_COLOR_COOL_GREY_900 = 'hsl(210, 24%, 16%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_50 = 'hsl(217, 53%, 93%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_100 = 'hsl(215, 47%, 81%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_200 = 'hsl(217, 44%, 69%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_300 = 'hsl(217, 41%, 59%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_400 = 'hsl(217, 41%, 50%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_500 = 'hsl(221, 50%, 40%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_600 = 'hsl(221, 56%, 32%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_700 = 'hsl(221, 56%, 24%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_800 = 'hsl(220, 56%, 17%)';
    public static SOUL_CHOICE_COLOR_DEEP_BLUE_900 = 'hsl(221, 61%, 11%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_50 = 'hsl(262, 61%, 93%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_100 = 'hsl(261, 67%, 84%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_200 = 'hsl(261, 54%, 68%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_300 = 'hsl(261, 47%, 58%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_400 = 'hsl(262, 43%, 51%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_500 = 'hsl(262, 48%, 46%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_600 = 'hsl(262, 60%, 38%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_700 = 'hsl(262, 69%, 31%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_800 = 'hsl(262, 72%, 25%)';
    public static SOUL_CHOICE_COLOR_DEEP_PURPLE_900 = 'hsl(263, 85%, 18%)';
    public static SOUL_CHOICE_COLOR_GRAPE_50 = 'hsl(317, 81%, 94%)';
    public static SOUL_CHOICE_COLOR_GRAPE_100 = 'hsl(318, 60%, 80%)';
    public static SOUL_CHOICE_COLOR_GRAPE_200 = 'hsl(318, 49%, 62%)';
    public static SOUL_CHOICE_COLOR_GRAPE_300 = 'hsl(318, 49%, 45%)';
    public static SOUL_CHOICE_COLOR_GRAPE_400 = 'hsl(318, 63%, 35%)';
    public static SOUL_CHOICE_COLOR_GRAPE_500 = 'hsl(317, 100%, 23%)';
    public static SOUL_CHOICE_COLOR_GRAPE_600 = 'hsl(319, 100%, 20%)';
    public static SOUL_CHOICE_COLOR_GRAPE_700 = 'hsl(319, 100%, 16%)';
    public static SOUL_CHOICE_COLOR_GRAPE_800 = 'hsl(321, 100%, 13%)';
    public static SOUL_CHOICE_COLOR_GRAPE_900 = 'hsl(324, 100%, 10%)';
    public static SOUL_CHOICE_COLOR_GREEN_50 = 'hsl(125, 39%, 94%)';
    public static SOUL_CHOICE_COLOR_GREEN_100 = 'hsl(126, 49%, 84%)';
    public static SOUL_CHOICE_COLOR_GREEN_200 = 'hsl(122, 42%, 75%)';
    public static SOUL_CHOICE_COLOR_GREEN_300 = 'hsl(123, 38%, 63%)';
    public static SOUL_CHOICE_COLOR_GREEN_400 = 'hsl(123, 35%, 51%)';
    public static SOUL_CHOICE_COLOR_GREEN_500 = 'hsl(122, 39%, 41%)';
    public static SOUL_CHOICE_COLOR_GREEN_600 = 'hsl(122, 47%, 35%)';
    public static SOUL_CHOICE_COLOR_GREEN_700 = 'hsl(125, 56%, 29%)';
    public static SOUL_CHOICE_COLOR_GREEN_800 = 'hsl(125, 73%, 20%)';
    public static SOUL_CHOICE_COLOR_GREEN_900 = 'hsl(125, 86%, 14%)';
    public static SOUL_CHOICE_COLOR_GREY_50 = 'hsl(0, 0%, 97%)';
    public static SOUL_CHOICE_COLOR_GREY_100 = 'hsl(0, 0%, 88%)';
    public static SOUL_CHOICE_COLOR_GREY_200 = 'hsl(0, 0%, 81%)';
    public static SOUL_CHOICE_COLOR_GREY_300 = 'hsl(0, 0%, 69%)';
    public static SOUL_CHOICE_COLOR_GREY_400 = 'hsl(0, 0%, 62%)';
    public static SOUL_CHOICE_COLOR_GREY_500 = 'hsl(0, 0%, 49%)';
    public static SOUL_CHOICE_COLOR_GREY_600 = 'hsl(0, 0%, 38%)';
    public static SOUL_CHOICE_COLOR_GREY_700 = 'hsl(0, 0%, 32%)';
    public static SOUL_CHOICE_COLOR_GREY_800 = 'hsl(0, 0%, 23%)';
    public static SOUL_CHOICE_COLOR_GREY_900 = 'hsl(0, 0%, 13%)';
    public static SOUL_CHOICE_COLOR_INDIGO_50 = 'hsl(222, 46%, 93%)';
    public static SOUL_CHOICE_COLOR_INDIGO_100 = 'hsl(226, 56%, 81%)';
    public static SOUL_CHOICE_COLOR_INDIGO_200 = 'hsl(227, 46%, 69%)';
    public static SOUL_CHOICE_COLOR_INDIGO_300 = 'hsl(227, 41%, 60%)';
    public static SOUL_CHOICE_COLOR_INDIGO_400 = 'hsl(227, 34%, 50%)';
    public static SOUL_CHOICE_COLOR_INDIGO_500 = 'hsl(227, 37%, 43%)';
    public static SOUL_CHOICE_COLOR_INDIGO_600 = 'hsl(226, 37%, 37%)';
    public static SOUL_CHOICE_COLOR_INDIGO_700 = 'hsl(228, 32%, 29%)';
    public static SOUL_CHOICE_COLOR_INDIGO_800 = 'hsl(227, 27%, 20%)';
    public static SOUL_CHOICE_COLOR_INDIGO_900 = 'hsl(227, 23%, 12%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_50 = 'hsl(201, 100%, 94%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_100 = 'hsl(202, 67%, 88%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_200 = 'hsl(203, 65%, 78%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_300 = 'hsl(203, 65%, 69%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_400 = 'hsl(204, 64%, 58%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_500 = 'hsl(204, 58%, 49%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_600 = 'hsl(204, 67%, 41%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_700 = 'hsl(204, 65%, 31%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_800 = 'hsl(204, 60%, 20%)';
    public static SOUL_CHOICE_COLOR_LIGHT_BLUE_900 = 'hsl(204, 54%, 15%)';
    public static SOUL_CHOICE_COLOR_ORANGE_50 = 'hsl(33, 82%, 96%)';
    public static SOUL_CHOICE_COLOR_ORANGE_100 = 'hsl(33, 84%, 88%)';
    public static SOUL_CHOICE_COLOR_ORANGE_200 = 'hsl(29, 85%, 80%)';
    public static SOUL_CHOICE_COLOR_ORANGE_300 = 'hsl(24, 86%, 72%)';
    public static SOUL_CHOICE_COLOR_ORANGE_400 = 'hsl(20, 86%, 64%)';
    public static SOUL_CHOICE_COLOR_ORANGE_500 = 'hsl(19, 86%, 56%)';
    public static SOUL_CHOICE_COLOR_ORANGE_600 = 'hsl(17, 69%, 44%)';
    public static SOUL_CHOICE_COLOR_ORANGE_700 = 'hsl(13, 71%, 29%)';
    public static SOUL_CHOICE_COLOR_ORANGE_800 = 'hsl(7, 74%, 16%)';
    public static SOUL_CHOICE_COLOR_ORANGE_900 = 'hsl(3, 78%, 11%)';
    public static SOUL_CHOICE_COLOR_PINK_50 = 'hsl(328, 57%, 95%)';
    public static SOUL_CHOICE_COLOR_PINK_100 = 'hsl(330, 67%, 88%)';
    public static SOUL_CHOICE_COLOR_PINK_200 = 'hsl(330, 62%, 73%)';
    public static SOUL_CHOICE_COLOR_PINK_300 = 'hsl(331, 60%, 64%)';
    public static SOUL_CHOICE_COLOR_PINK_400 = 'hsl(330, 58%, 55%)';
    public static SOUL_CHOICE_COLOR_PINK_500 = 'hsl(330, 58%, 45%)';
    public static SOUL_CHOICE_COLOR_PINK_600 = 'hsl(330, 56%, 40%)';
    public static SOUL_CHOICE_COLOR_PINK_700 = 'hsl(330, 50%, 32%)';
    public static SOUL_CHOICE_COLOR_PINK_800 = 'hsl(330, 44%, 23%)';
    public static SOUL_CHOICE_COLOR_PINK_900 = 'hsl(329, 39%, 14%)';
    public static SOUL_CHOICE_COLOR_PURPLE_50 = 'hsl(295, 58%, 93%)';
    public static SOUL_CHOICE_COLOR_PURPLE_100 = 'hsl(293, 67%, 85%)';
    public static SOUL_CHOICE_COLOR_PURPLE_200 = 'hsl(293, 54%, 68%)';
    public static SOUL_CHOICE_COLOR_PURPLE_300 = 'hsl(293, 48%, 58%)';
    public static SOUL_CHOICE_COLOR_PURPLE_400 = 'hsl(294, 43%, 51%)';
    public static SOUL_CHOICE_COLOR_PURPLE_500 = 'hsl(294, 48%, 46%)';
    public static SOUL_CHOICE_COLOR_PURPLE_600 = 'hsl(294, 60%, 38%)';
    public static SOUL_CHOICE_COLOR_PURPLE_700 = 'hsl(294, 68%, 32%)';
    public static SOUL_CHOICE_COLOR_PURPLE_800 = 'hsl(294, 72%, 25%)';
    public static SOUL_CHOICE_COLOR_PURPLE_900 = 'hsl(295, 85%, 18%)';
    public static SOUL_CHOICE_COLOR_RED_50 = 'hsl(0, 89%, 96%)';
    public static SOUL_CHOICE_COLOR_RED_100 = 'hsl(0, 82%, 89%)';
    public static SOUL_CHOICE_COLOR_RED_200 = 'hsl(0, 77%, 78%)';
    public static SOUL_CHOICE_COLOR_RED_300 = 'hsl(0, 71%, 66%)';
    public static SOUL_CHOICE_COLOR_RED_400 = 'hsl(0, 64%, 55%)';
    public static SOUL_CHOICE_COLOR_RED_500 = 'hsl(0, 67%, 44%)';
    public static SOUL_CHOICE_COLOR_RED_600 = 'hsl(0, 72%, 38%)';
    public static SOUL_CHOICE_COLOR_RED_700 = 'hsl(0, 79%, 32%)';
    public static SOUL_CHOICE_COLOR_RED_800 = 'hsl(0, 85%, 25%)';
    public static SOUL_CHOICE_COLOR_RED_900 = 'hsl(0, 92%, 20%)';
    public static SOUL_CHOICE_COLOR_HSLA_LIGHT_WHITE = 'hsla(0, 0%, 100%, 0.8)';
    public static SOUL_CHOICE_COLOR_TEAL_50 = 'hsl(192, 32%, 91%)';
    public static SOUL_CHOICE_COLOR_TEAL_100 = 'hsl(189, 38%, 76%)';
    public static SOUL_CHOICE_COLOR_TEAL_200 = 'hsl(187, 41%, 61%)';
    public static SOUL_CHOICE_COLOR_TEAL_300 = 'hsl(184, 44%, 48%)';
    public static SOUL_CHOICE_COLOR_TEAL_400 = 'hsl(181, 65%, 36%)';
    public static SOUL_CHOICE_COLOR_TEAL_500 = 'hsl(183, 94%, 28%)';
    public static SOUL_CHOICE_COLOR_TEAL_600 = 'hsl(183, 95%, 23%)';
    public static SOUL_CHOICE_COLOR_TEAL_700 = 'hsl(183, 96%, 18%)';
    public static SOUL_CHOICE_COLOR_TEAL_800 = 'hsl(183, 94%, 13%)';
    public static SOUL_CHOICE_COLOR_TEAL_900 = 'hsl(183, 95%, 8%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_50 = 'hsl(40, 23%, 97%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_100 = 'hsl(43, 13%, 90%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_200 = 'hsl(40, 15%, 80%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_300 = 'hsl(39, 11%, 69%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_400 = 'hsl(41, 8%, 61%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_500 = 'hsl(41, 8%, 48%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_600 = 'hsl(41, 9%, 35%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_700 = 'hsl(38, 11%, 28%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_800 = 'hsl(40, 13%, 23%)';
    public static SOUL_CHOICE_COLOR_WARM_GREY_900 = 'hsl(42, 15%, 13%)';
    public static SOUL_CHOICE_COLOR_YELLOW_50 = 'hsl(45, 100%, 96%)';
    public static SOUL_CHOICE_COLOR_YELLOW_100 = 'hsl(45, 90%, 88%)';
    public static SOUL_CHOICE_COLOR_YELLOW_200 = 'hsl(45, 86%, 81%)';
    public static SOUL_CHOICE_COLOR_YELLOW_300 = 'hsl(43, 90%, 76%)';
    public static SOUL_CHOICE_COLOR_YELLOW_400 = 'hsl(43, 89%, 70%)';
    public static SOUL_CHOICE_COLOR_YELLOW_500 = 'hsl(42, 78%, 60%)';
    public static SOUL_CHOICE_COLOR_YELLOW_600 = 'hsl(42, 63%, 48%)';
    public static SOUL_CHOICE_COLOR_YELLOW_700 = 'hsl(43, 72%, 37%)';
    public static SOUL_CHOICE_COLOR_YELLOW_800 = 'hsl(43, 77%, 27%)';
    public static SOUL_CHOICE_COLOR_YELLOW_900 = 'hsl(43, 86%, 17%)';
    public static SOUL_CHOICE_FONT_SIZE_9 = '.5625rem';
    public static SOUL_CHOICE_FONT_SIZE_11 = '.6875rem';
    public static SOUL_CHOICE_FONT_SIZE_13 = '.8125rem';
    public static SOUL_CHOICE_FONT_SIZE_16 = '1rem';
    public static SOUL_CHOICE_FONT_SIZE_18 = '1.125rem';
    public static SOUL_CHOICE_FONT_SIZE_22 = '1.375rem';
    public static SOUL_CHOICE_FONT_SIZE_27 = '1.6875rem';
    public static SOUL_CHOICE_FONT_SIZE_32 = '2rem';
    public static SOUL_CHOICE_FONT_SIZE_39 = '2.4375rem';
    public static SOUL_CHOICE_FONT_SIZE_47 = '2.9375rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_8 = '.5rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_10 = '.625rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_12 = '.75rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_16 = '1rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_18 = '1.125rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_22 = '1.375rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_27 = '1.6875rem';
    public static SOUL_CHOICE_ICON_FONT_SIZE_32 = '2rem';
    public static SOUL_CHOICE_LINE_HEIGHT_12 = '.75rem';
    public static SOUL_CHOICE_LINE_HEIGHT_16 = '1rem';
    public static SOUL_CHOICE_LINE_HEIGHT_20 = '1.25rem';
    public static SOUL_CHOICE_LINE_HEIGHT_24 = '1.5rem';
    public static SOUL_CHOICE_LINE_HEIGHT_28 = '1.75rem';
    public static SOUL_CHOICE_LINE_HEIGHT_36 = '2.25rem';
    public static SOUL_CHOICE_LINE_HEIGHT_40 = '2.5rem';
    public static SOUL_CHOICE_LINE_HEIGHT_48 = '3rem';
    public static SOUL_CHOICE_LINE_HEIGHT_58 = '3.625rem';
    public static SOUL_CHOICE_SPACE_2 = '.125rem';
    public static SOUL_CHOICE_SPACE_4 = '.25rem';
    public static SOUL_CHOICE_SPACE_8 = '.5rem';
    public static SOUL_CHOICE_SPACE_12 = '.75rem';
    public static SOUL_CHOICE_SPACE_16 = '1rem';
    public static SOUL_CHOICE_SPACE_24 = '1.5rem';
    public static SOUL_CHOICE_SPACE_32 = '2rem';
    public static SOUL_CHOICE_SPACE_48 = '3rem';
    public static SOUL_CHOICE_SPACE_64 = '4rem';
    public static SOUL_CHOICE_SPACE_96 = '6rem';
    public static SOUL_CHOICE_SPACE_128 = '8rem';
    public static SOUL_CHOICE_SPACE_192 = '12rem';
    public static SOUL_CHOICE_SPACE_256 = '16rem';
    public static SOUL_CHOICE_SPACE_384 = '24rem';
    public static SOUL_CHOICE_SPACE_512 = '32rem';
    public static SOUL_CHOICE_SPACE_640 = '40rem';
    public static SOUL_CHOICE_SPACE_768 = '48rem';
    public static SOUL_CHOICE_SPACE_PX_1 = '1px';
    public static SOUL_CHOICE_SPACE_PX_2 = '2px';
    public static SOUL_CHOICE_SPACE_PX_4 = '4px';
    public static SOUL_COLOR_BACKGROUND_ACTIVE = 'hsl(210, 36%, 96%)';
    public static SOUL_COLOR_BACKGROUND_ALTERNATIVE = 'hsl(0, 0%, 97%)';
    public static SOUL_COLOR_BACKGROUND_DESIGN_FEASIBLE = 'hsl(126, 49%, 84%)';
    public static SOUL_COLOR_BACKGROUND_DESIGN_UNFEASIBLE = 'hsl(45, 90%, 88%)';
    public static SOUL_COLOR_BACKGROUND_DESIGN_ERROR = 'hsl(0, 82%, 89%)';
    public static SOUL_COLOR_BACKGROUND_DESIGN_BEST = 'hsl(205, 97%, 85%)';
    public static SOUL_COLOR_BACKGROUND_DESIGN_RERUN = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_BACKGROUND_DISABLED = 'hsl(0, 0%, 97%)';
    public static SOUL_COLOR_BACKGROUND_HIGHLIGHT = 'hsl(210, 36%, 96%)';
    public static SOUL_COLOR_BACKGROUND_HOVER_ON_LIGHT = 'hsl(0, 0%, 97%)';
    public static SOUL_COLOR_BACKGROUND_ICON = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_PRIMARY = 'hsl(210, 36%, 96%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_DEFAULT = 'hsl(0, 0%, 97%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_NEGATIVE = 'hsl(0, 89%, 96%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_POSITIVE = 'hsl(125, 39%, 94%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_WARNING = 'hsl(45, 100%, 96%)';
    public static SOUL_COLOR_BACKGROUND_INDICATOR_INFORMATIVE = 'hsl(217, 53%, 93%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_DEFAULT_NORMAL = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_DEFAULT_SOLID = 'hsl(0, 0%, 23%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_DEFAULT_HOVER = 'hsl(0, 0%, 81%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_PRIMARY_NORMAL = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_PRIMARY_HOVER = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_PRIMARY_ACTIVE = 'hsl(210, 36%, 96%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_NEGATIVE_NORMAL = 'hsl(0, 64%, 55%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_NEGATIVE_HOVER = 'hsl(0, 67%, 44%)';
    public static SOUL_COLOR_BACKGROUND_INTERACTIVE_DISABLED = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_BACKGROUND_INVERSE_DEFAULT = 'hsl(0, 0%, 23%)';
    public static SOUL_COLOR_BACKGROUND_INVERSE_LIGHT = 'hsl(209, 14%, 37%)';
    public static SOUL_COLOR_BACKGROUND_OVERLAY_LIGHT = 'hsla(0, 0%, 100%, 0.8)';
    public static SOUL_COLOR_BACKGROUND_PRIMARY = 'hsl(0, 0%, 100%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_ACTIVE = 'hsl(122, 39%, 41%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_POSITIVE = 'hsl(122, 39%, 41%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_WARNING = 'hsl(42, 78%, 60%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_CRITICAL = 'hsl(0, 67%, 44%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_INACTIVE = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_NEUTRAL = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_INFORMATIVE = 'hsl(205, 76%, 39%)';
    public static SOUL_COLOR_BACKGROUND_STATUS_BRAND_PRIMARY = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BORDER_ACTIVE = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BORDER_FOCUS = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BORDER_GOAL_INPUT = 'hsl(123, 38%, 63%)';
    public static SOUL_COLOR_BORDER_GOAL_OUTPUT = 'hsl(205, 84%, 74%)';
    public static SOUL_COLOR_BORDER_GOAL_CONSTRAINT = 'hsl(24, 86%, 72%)';
    public static SOUL_COLOR_BORDER_GOAL_OBJECTIVE = 'hsl(217, 41%, 50%)';
    public static SOUL_COLOR_BORDER_GOAL_EXPRESSION = 'hsl(293, 48%, 58%)';
    public static SOUL_COLOR_BORDER_HIGHLIGHT = 'hsl(212, 33%, 89%)';
    public static SOUL_COLOR_BORDER_INDICATOR_PRIMARY = 'hsl(212, 33%, 89%)';
    public static SOUL_COLOR_BORDER_INDICATOR_DEFAULT = 'hsl(0, 0%, 81%)';
    public static SOUL_COLOR_BORDER_INDICATOR_NEGATIVE = 'hsl(0, 82%, 89%)';
    public static SOUL_COLOR_BORDER_INDICATOR_POSITIVE = 'hsl(126, 49%, 84%)';
    public static SOUL_COLOR_BORDER_INDICATOR_WARNING = 'hsl(45, 86%, 81%)';
    public static SOUL_COLOR_BORDER_NEGATIVE = 'hsl(0, 67%, 44%)';
    public static SOUL_COLOR_BORDER_ON_DARK = 'hsl(0, 0%, 32%)';
    public static SOUL_COLOR_BORDER_ON_LIGHT = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_BORDER_OUTLINE_PRIMARY = 'hsl(210, 31%, 80%)';
    public static SOUL_COLOR_BORDER_OUTLINE_NEGATIVE = 'hsl(0, 77%, 78%)';
    public static SOUL_COLOR_BORDER_PRIMARY = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BORDER_WARNING = 'hsl(42, 78%, 60%)';
    public static SOUL_COLOR_BRAND_BASE = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BRAND_HOVER = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_BRAND_PRIMARY_50 = 'hsl(210, 36%, 96%)';
    public static SOUL_COLOR_BRAND_PRIMARY_100 = 'hsl(212, 33%, 89%)';
    public static SOUL_COLOR_BRAND_PRIMARY_200 = 'hsl(210, 31%, 80%)';
    public static SOUL_COLOR_BRAND_PRIMARY_300 = 'hsl(211, 27%, 70%)';
    public static SOUL_COLOR_BRAND_PRIMARY_400 = 'hsl(209, 23%, 60%)';
    public static SOUL_COLOR_BRAND_PRIMARY_500 = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_BRAND_PRIMARY_600 = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_BRAND_PRIMARY_700 = 'hsl(209, 34%, 30%)';
    public static SOUL_COLOR_BRAND_PRIMARY_800 = 'hsl(211, 39%, 23%)';
    public static SOUL_COLOR_BRAND_PRIMARY_900 = 'hsl(209, 61%, 16%)';
    public static SOUL_COLOR_BRAND_SECONDARY_50 = 'hsl(201, 100%, 94%)';
    public static SOUL_COLOR_BRAND_SECONDARY_100 = 'hsl(202, 67%, 88%)';
    public static SOUL_COLOR_BRAND_SECONDARY_200 = 'hsl(203, 65%, 78%)';
    public static SOUL_COLOR_BRAND_SECONDARY_300 = 'hsl(203, 65%, 69%)';
    public static SOUL_COLOR_BRAND_SECONDARY_400 = 'hsl(204, 64%, 58%)';
    public static SOUL_COLOR_BRAND_SECONDARY_500 = 'hsl(204, 58%, 49%)';
    public static SOUL_COLOR_BRAND_SECONDARY_600 = 'hsl(204, 67%, 41%)';
    public static SOUL_COLOR_BRAND_SECONDARY_700 = 'hsl(204, 65%, 31%)';
    public static SOUL_COLOR_BRAND_SECONDARY_800 = 'hsl(204, 60%, 20%)';
    public static SOUL_COLOR_BRAND_SECONDARY_900 = 'hsl(204, 54%, 15%)';
    public static SOUL_COLOR_GRADIENT_FADE_OUT = 'linear-gradient(hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%))';
    public static SOUL_COLOR_GRADIENT_SHIMMER = 'linear-gradient(to right, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0.5) 33%, hsla(0, 0%, 100%, 0.5) 66%, hsla(0, 0%, 100%, 0) 100%)';
    public static SOUL_COLOR_ILLUSTRATION_BASE = 'hsl(0, 0%, 81%)';
    public static SOUL_COLOR_ILLUSTRATION_BRAND = 'hsl(210, 22%, 49%)';
    public static SOUL_COLOR_ILLUSTRATION_DARK = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_ILLUSTRATION_LIGHT = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_50 = 'hsl(217, 53%, 93%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_100 = 'hsl(215, 47%, 81%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_200 = 'hsl(217, 44%, 69%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_300 = 'hsl(217, 41%, 59%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_400 = 'hsl(217, 41%, 50%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_500 = 'hsl(221, 50%, 40%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_600 = 'hsl(221, 56%, 32%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_700 = 'hsl(221, 56%, 24%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_800 = 'hsl(220, 56%, 17%)';
    public static SOUL_COLOR_SUPPORT_INFORMATIVE_900 = 'hsl(221, 61%, 11%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_50 = 'hsl(0, 89%, 96%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_100 = 'hsl(0, 82%, 89%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_200 = 'hsl(0, 77%, 78%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_300 = 'hsl(0, 71%, 66%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_400 = 'hsl(0, 64%, 55%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_500 = 'hsl(0, 67%, 44%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_600 = 'hsl(0, 72%, 38%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_700 = 'hsl(0, 79%, 32%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_800 = 'hsl(0, 85%, 25%)';
    public static SOUL_COLOR_SUPPORT_NEGATIVE_900 = 'hsl(0, 92%, 20%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_50 = 'hsl(125, 39%, 94%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_100 = 'hsl(126, 49%, 84%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_200 = 'hsl(122, 42%, 75%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_300 = 'hsl(123, 38%, 63%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_400 = 'hsl(123, 35%, 51%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_500 = 'hsl(122, 39%, 41%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_600 = 'hsl(122, 47%, 35%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_700 = 'hsl(125, 56%, 29%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_800 = 'hsl(125, 73%, 20%)';
    public static SOUL_COLOR_SUPPORT_POSITIVE_900 = 'hsl(125, 86%, 14%)';
    public static SOUL_COLOR_SUPPORT_WARNING_50 = 'hsl(45, 100%, 96%)';
    public static SOUL_COLOR_SUPPORT_WARNING_100 = 'hsl(45, 90%, 88%)';
    public static SOUL_COLOR_SUPPORT_WARNING_200 = 'hsl(45, 86%, 81%)';
    public static SOUL_COLOR_SUPPORT_WARNING_300 = 'hsl(43, 90%, 76%)';
    public static SOUL_COLOR_SUPPORT_WARNING_400 = 'hsl(43, 89%, 70%)';
    public static SOUL_COLOR_SUPPORT_WARNING_500 = 'hsl(42, 78%, 60%)';
    public static SOUL_COLOR_SUPPORT_WARNING_600 = 'hsl(42, 63%, 48%)';
    public static SOUL_COLOR_SUPPORT_WARNING_700 = 'hsl(43, 72%, 37%)';
    public static SOUL_COLOR_SUPPORT_WARNING_800 = 'hsl(43, 77%, 27%)';
    public static SOUL_COLOR_SUPPORT_WARNING_900 = 'hsl(43, 86%, 17%)';
    public static SOUL_COLOR_NEUTRAL_50 = 'hsl(0, 0%, 97%)';
    public static SOUL_COLOR_NEUTRAL_100 = 'hsl(0, 0%, 88%)';
    public static SOUL_COLOR_NEUTRAL_200 = 'hsl(0, 0%, 81%)';
    public static SOUL_COLOR_NEUTRAL_300 = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_NEUTRAL_400 = 'hsl(0, 0%, 62%)';
    public static SOUL_COLOR_NEUTRAL_500 = 'hsl(0, 0%, 49%)';
    public static SOUL_COLOR_NEUTRAL_600 = 'hsl(0, 0%, 38%)';
    public static SOUL_COLOR_NEUTRAL_700 = 'hsl(0, 0%, 32%)';
    public static SOUL_COLOR_NEUTRAL_800 = 'hsl(0, 0%, 23%)';
    public static SOUL_COLOR_NEUTRAL_900 = 'hsl(0, 0%, 13%)';
    public static SOUL_COLOR_TEXT_ACTIVE = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_TEXT_LINK = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_TEXT_DESIGN_BEST = 'hsl(205, 82%, 33%)';
    public static SOUL_COLOR_TEXT_DESIGN_FEASIBLE = 'hsl(125, 56%, 29%)';
    public static SOUL_COLOR_TEXT_DESIGN_UNFEASIBLE = 'hsl(43, 77%, 27%)';
    public static SOUL_COLOR_TEXT_DESIGN_ERROR = 'hsl(0, 79%, 32%)';
    public static SOUL_COLOR_TEXT_DESIGN_RERUN = 'hsl(0, 0%, 32%)';
    public static SOUL_COLOR_TEXT_DISABLED = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_TEXT_HEADER = 'hsl(0, 0%, 23%)';
    public static SOUL_COLOR_TEXT_INDICATOR_PRIMARY = 'hsl(209, 28%, 39%)';
    public static SOUL_COLOR_TEXT_INDICATOR_DEFAULT = 'hsl(0, 0%, 32%)';
    public static SOUL_COLOR_TEXT_INDICATOR_NEGATIVE = 'hsl(0, 72%, 38%)';
    public static SOUL_COLOR_TEXT_INDICATOR_POSITIVE = 'hsl(125, 56%, 29%)';
    public static SOUL_COLOR_TEXT_INDICATOR_WARNING = 'hsl(43, 77%, 27%)';
    public static SOUL_COLOR_TEXT_INDICATOR_INFORMATIVE = 'hsl(221, 50%, 40%)';
    public static SOUL_COLOR_TEXT_ON_DARK = 'hsl(0, 0%, 100%)';
    public static SOUL_COLOR_TEXT_ON_LIGHT = 'hsl(0, 0%, 23%)';
    public static SOUL_COLOR_TEXT_PLACEHOLDER = 'hsl(0, 0%, 69%)';
    public static SOUL_COLOR_TEXT_REDUCED_ON_LIGHT = 'hsl(0, 0%, 49%)';
    public static SOUL_COLOR_TEXT_STRONG_ON_LIGHT = 'hsl(0, 0%, 13%)';
    public static SOUL_COLOR_VISUALIZATION_DESIGN_BEST = 'hsl(205, 84%, 74%)';
    public static SOUL_COLOR_VISUALIZATION_DESIGN_FEASIBLE = 'hsl(123, 35%, 51%)';
    public static SOUL_COLOR_VISUALIZATION_DESIGN_ERROR = 'hsl(0, 64%, 55%)';
    public static SOUL_COLOR_VISUALIZATION_DESIGN_UNFEASIBLE = 'hsl(42, 78%, 60%)';
    public static SOUL_COLOR_VISUALIZATION_DESIGN_NOT_EVALUATED = 'hsl(0, 0%, 81%)';
    public static SOUL_FONT_SIZE_2_XS = '.5625rem';
    public static SOUL_FONT_SIZE_XS = '.6875rem';
    public static SOUL_FONT_SIZE_S = '.8125rem';
    public static SOUL_FONT_SIZE_M = '1rem';
    public static SOUL_FONT_SIZE_L = '1.125rem';
    public static SOUL_FONT_SIZE_XL = '1.375rem';
    public static SOUL_FONT_SIZE_2_XL = '1.6875rem';
    public static SOUL_FONT_SIZE_3_XL = '2rem';
    public static SOUL_ICON_FONT_SIZE_2_XS = '.5rem';
    public static SOUL_ICON_FONT_SIZE_XS = '.625rem';
    public static SOUL_ICON_FONT_SIZE_S = '.75rem';
    public static SOUL_ICON_FONT_SIZE_M = '1rem';
    public static SOUL_ICON_FONT_SIZE_L = '1.125rem';
    public static SOUL_ICON_FONT_SIZE_XL = '1.375rem';
    public static SOUL_ICON_FONT_SIZE_2_XL = '1.6875rem';
    public static SOUL_ICON_FONT_SIZE_3_XL = '2rem';
    public static SOUL_LINE_HEIGHT_UI_2_XS = '.75rem';
    public static SOUL_LINE_HEIGHT_UI_XS = '1rem';
    public static SOUL_LINE_HEIGHT_UI_S = '1rem';
    public static SOUL_LINE_HEIGHT_UI_M = '1.25rem';
    public static SOUL_LINE_HEIGHT_UI_L = '1.5rem';
    public static SOUL_LINE_HEIGHT_UI_XL = '1.75rem';
    public static SOUL_LINE_HEIGHT_UI_2_XL = '2.25rem';
    public static SOUL_LINE_HEIGHT_UI_3_XL = '2.5rem';
    public static SOUL_LINE_HEIGHT_CONTENT_2_XS = '1rem';
    public static SOUL_LINE_HEIGHT_CONTENT_XS = '1rem';
    public static SOUL_LINE_HEIGHT_CONTENT_S = '1.25rem';
    public static SOUL_LINE_HEIGHT_CONTENT_M = '1.5rem';
    public static SOUL_LINE_HEIGHT_CONTENT_L = '1.75rem';
    public static SOUL_LINE_HEIGHT_CONTENT_XL = '2.25rem';
    public static SOUL_LINE_HEIGHT_CONTENT_2_XL = '2.5rem';
    public static SOUL_LINE_HEIGHT_CONTENT_3_XL = '3rem';
    public static SOUL_TYPEFACE_STACK_TEXT = '\'soul-sans\', \'Helvetica Neue\', Arial, sans-serif';
    public static SOUL_TYPEFACE_STACK_MONOSPACED = '\'soul-mono\', \'Menlo\', \'DejaVu Sans Mono\', \'Bitstream Vera Sans Mono\', Courier, monospace';
    public static SOUL_SHADOW_BUTTON_NORMAL = 'inset 0 -1px 0 0 hsla(210, 24%, 16%, .1)';
    public static SOUL_SHADOW_BUTTON_ACTIVE = 'inset 0 1px 0 0 hsla(210, 24%, 16%, .1)';
    public static SOUL_SHADOW_OUTLINE_DEFAULT = '0px 0px 0px 2px hsl(210, 31%, 80%)';
    public static SOUL_SHADOW_OUTLINE_NEGATIVE = '0px 0px 0px 2px hsl(0, 77%, 78%)';
    public static SOUL_SHADOW_BORDER_THIN = '0 0 0 1px hsl(0, 0%, 100%)';
    public static SOUL_SHADOW_BORDER_THICK = '0 0 0 2px hsl(0, 0%, 100%)';
    public static SOUL_SHADOW_BORDER_THICKER = '0 0 0 4px hsl(0, 0%, 100%)';
    public static SOUL_BORDER_RADIUS_M = '.25rem';
    public static SOUL_BORDER_RADIUS_L = '.75rem';
    public static SOUL_BORDER_RADIUS_CIRCLE = '50%';
    public static SOUL_BORDER_RADIUS_TOP_M = '.25rem .25rem 0 0';
    public static SOUL_BORDER_RADIUS_TOP_L = '.75rem .75rem 0 0';
    public static SOUL_BORDER_RADIUS_BOTTOM_M = '0 0 .25rem .25rem';
    public static SOUL_BORDER_RADIUS_BOTTOM_L = '0 0 .75rem .75rem';
    public static SOUL_BORDER_THIN = '1px';
    public static SOUL_BORDER_THICK = '2px';
    public static SOUL_BORDER_THICKER = '4px';
    public static SOUL_DEVICE_S = '32rem';
    public static SOUL_DEVICE_M = '40rem';
    public static SOUL_DEVICE_L = '48rem';
    public static SOUL_SPACE_LAYOUT_3_XS = '1.5rem';
    public static SOUL_SPACE_LAYOUT_2_XS = '2rem';
    public static SOUL_SPACE_LAYOUT_XS = '3rem';
    public static SOUL_SPACE_LAYOUT_S = '4rem';
    public static SOUL_SPACE_LAYOUT_M = '6rem';
    public static SOUL_SPACE_LAYOUT_L = '8rem';
    public static SOUL_SPACE_LAYOUT_XL = '12rem';
    public static SOUL_SPACE_LAYOUT_2_XL = '16rem';
    public static SOUL_SPACE_LAYOUT_3_XL = '24rem';
    public static SOUL_SPACE_INLINE_RIGHT_3_XS = '0 .125rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_2_XS = '0 .25rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_XS = '0 .5rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_S = '0 .75rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_M = '0 1rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_L = '0 1.5rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_XL = '0 2rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_2_XL = '0 3rem 0 0';
    public static SOUL_SPACE_INLINE_RIGHT_3_XL = '0 4rem 0 0';
    public static SOUL_SPACE_INLINE_LEFT_3_XS = '0 0 0 .125rem';
    public static SOUL_SPACE_INLINE_LEFT_2_XS = '0 0 0 .25rem';
    public static SOUL_SPACE_INLINE_LEFT_XS = '0 0 0 .5rem';
    public static SOUL_SPACE_INLINE_LEFT_S = '0 0 0 .75rem';
    public static SOUL_SPACE_INLINE_LEFT_M = '0 0 0 1rem';
    public static SOUL_SPACE_INLINE_LEFT_L = '0 0 0 1.5rem';
    public static SOUL_SPACE_INLINE_LEFT_XL = '0 0 0 2rem';
    public static SOUL_SPACE_INLINE_LEFT_2_XL = '0 0 0 3rem';
    public static SOUL_SPACE_INLINE_LEFT_3_XL = '0 0 0 4rem';
    public static SOUL_SPACE_INLINE_BOTH_3_XS = '0 .125rem 0 .125rem';
    public static SOUL_SPACE_INLINE_BOTH_2_XS = '0 .25rem 0 .25rem';
    public static SOUL_SPACE_INLINE_BOTH_XS = '0 .5rem 0 .5rem';
    public static SOUL_SPACE_INLINE_BOTH_S = '0 .75rem 0 .75rem';
    public static SOUL_SPACE_INLINE_BOTH_M = '0 1rem 0 1rem';
    public static SOUL_SPACE_INLINE_BOTH_L = '0 1.5rem 0 1.5rem';
    public static SOUL_SPACE_INLINE_BOTH_XL = '0 2rem 0 2rem';
    public static SOUL_SPACE_INLINE_BOTH_2_XL = '0 3rem 0 3rem';
    public static SOUL_SPACE_INLINE_BOTH_3_XL = '0 4rem 0 4rem';
    public static SOUL_SPACE_INSET_3_XS = '.125rem';
    public static SOUL_SPACE_INSET_2_XS = '.25rem';
    public static SOUL_SPACE_INSET_XS = '.5rem';
    public static SOUL_SPACE_INSET_S = '.75rem';
    public static SOUL_SPACE_INSET_M = '1rem';
    public static SOUL_SPACE_INSET_L = '1.5rem';
    public static SOUL_SPACE_INSET_XL = '2rem';
    public static SOUL_SPACE_INSET_2_XL = '3rem';
    public static SOUL_SPACE_INSET_3_XL = '4rem';
    public static SOUL_SPACE_SQUISH_2_XS = '.125rem .25rem';
    public static SOUL_SPACE_SQUISH_XS = '.25rem .5rem';
    public static SOUL_SPACE_SQUISH_S = '.25rem .75rem';
    public static SOUL_SPACE_SQUISH_M = '.5rem 1rem';
    public static SOUL_SPACE_SQUISH_L = '.75rem 1.5rem';
    public static SOUL_SPACE_SQUISH_XL = '1rem 2rem';
    public static SOUL_SPACE_SQUISH_2_XL = '1.5rem 3rem';
    public static SOUL_SPACE_SQUISH_3_XL = '2rem 4rem';
    public static SOUL_SPACE_STACK_TOP_3_XS = '.125rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_2_XS = '.25rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_XS = '.5rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_S = '.75rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_M = '1rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_L = '1.5rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_XL = '2rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_2_XL = '3rem 0 0 0';
    public static SOUL_SPACE_STACK_TOP_3_XL = '4rem 0 0 0';
    public static SOUL_SPACE_STACK_BOTTOM_3_XS = '0 0 .125rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_2_XS = '0 0 .25rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_XS = '0 0 .5rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_S = '0 0 .75rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_M = '0 0 1rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_L = '0 0 1.5rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_XL = '0 0 2rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_2_XL = '0 0 3rem 0';
    public static SOUL_SPACE_STACK_BOTTOM_3_XL = '0 0 4rem 0';
    public static SOUL_SPACE_STACK_BOTH_3_XS = '.125rem 0 .125rem 0';
    public static SOUL_SPACE_STACK_BOTH_2_XS = '.25rem 0 .25rem 0';
    public static SOUL_SPACE_STACK_BOTH_XS = '.5rem 0 .5rem 0';
    public static SOUL_SPACE_STACK_BOTH_S = '.75rem 0 .75rem 0';
    public static SOUL_SPACE_STACK_BOTH_M = '1rem 0 1rem 0';
    public static SOUL_SPACE_STACK_BOTH_L = '1.5rem 0 1.5rem 0';
    public static SOUL_SPACE_STACK_BOTH_XL = '2rem 0 2rem 0';
    public static SOUL_SPACE_STACK_BOTH_2_XL = '3rem 0 3rem 0';
    public static SOUL_SPACE_STACK_BOTH_3_XL = '4rem 0 4rem 0';
    public static SOUL_SPACE_3_XS = '.125rem';
    public static SOUL_SPACE_2_XS = '.25rem';
    public static SOUL_SPACE_XS = '.5rem';
    public static SOUL_SPACE_S = '.75rem';
    public static SOUL_SPACE_M = '1rem';
    public static SOUL_SPACE_L = '1.5rem';
    public static SOUL_SPACE_XL = '2rem';
    public static SOUL_SPACE_2_XL = '3rem';
    public static SOUL_SPACE_3_XL = '4rem';
}
