import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'soulFileSize'})
export class FileSizePipe implements PipeTransform {

  public transform(value: string): string {
    const size: number = parseFloat(value);
    const i: number = Math.floor(Math.log(size) / Math.log(1024));
    const fractionDigits: number = size >= 1024 ? 2 : 0;
    const formattedSize = (size / Math.pow(1024, i)).toFixed(fractionDigits);

    return (!size && '0 Bytes' || `${formattedSize} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]}`);
  }

}
