import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Objects} from '../../utils/Objects';
import {Arrays} from '../../utils/Arrays';
import {Labelled} from '../../models/Labelled';
import {SoulTag} from '../../models/SoulTag';
import {Keymap} from '../../utils/Keymap';
import {CreatableSelectComponent} from '../../select/components/creatable-select.component';

@Component({
  selector: 'soul-taglist-editor',
  templateUrl: 'taglist-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaglistEditorComponent implements OnInit {
  @Input()
  public availableTags: Array<Labelled> = [];
  @Input()
  public appliedTags: Array<Labelled> = [];
  @Input()
  public selectTemplate: any;
  @Input()
  public tagTemplate: any;
  @Input()
  public selectFilter: (source: any, filterTerm: string) => boolean;
  @Input()
  public enableNewTagAddition = false;
  @Input()
  public placeholder = 'Type here...';

  @ViewChild(CreatableSelectComponent)
  public creatableSelect: CreatableSelectComponent;

  @ViewChildren('buttons')
  public buttons: QueryList<ElementRef>;

  public removeTag(index: number): void {
    this.availableTags.push(this.appliedTags[index]);
    Arrays.removeElementAtIndex(this.appliedTags, index);
    setTimeout(() => this.moveFocus(index), 0);
  }

  public ngOnInit(): void {
    for (const subject of this.appliedTags) {
      if (Objects.isNotNull(this.availableTags) && Arrays.contains(this.availableTags, subject)) {
        Arrays.removeElement(this.availableTags, subject);
      }
    }
  }

  public onTagChoose(selectedTag: Labelled | string): void {
    const isTag: boolean = Objects.isDefined((selectedTag as Labelled).label);
    if (!isTag && this.enableNewTagAddition) {
      this.createTag(selectedTag.toString());
    } else {
      this.addTag(selectedTag as Labelled);
    }
  }

  public onSelectKeyDown(event: KeyboardEvent): void {
    if (this.isMovingFocus(event)) {
      this.focusLastRemoveButton();
    }
  }

  public onRemoveButtonKeyDown(event: KeyboardEvent, i: number): void {
    switch (event.key) {
      case Keymap.BACKSPACE:
      case Keymap.DEL:
        this.removeTag(i);
        break;
      case Keymap.LEFT:
      case Keymap.UP:
        this.moveFocus(i - 1);
        break;
      case Keymap.RIGHT:
      case Keymap.DOWN:
        this.moveFocus(i + 1);
        break;
      default:
        break;
    }
  }

  private isMovingFocus(event: KeyboardEvent): boolean {
    return (event.key === Keymap.BACKSPACE || event.key === Keymap.LEFT) && !this.creatableSelect.filterTerm;
  }

  private addTag(selectedTag: Labelled): void {
    const selectedTagAvailable: boolean = this.availableTags.includes(selectedTag);
    if (selectedTagAvailable) {
      this.appliedTags.push(selectedTag);
      Arrays.removeElement(this.availableTags, selectedTag);
    }
  }

  private createTag(label: string): void {
    const labelAvailable: boolean = this.appliedTags.every(tag => tag.label !== label);
    if (labelAvailable) {
      const newTag: boolean = this.availableTags.every(tag => tag.label !== label);
      if (newTag) {
        this.applyNewTag(label);
      } else {
        this.applyAvailableTag(label);
      }
    }
  }

  private applyNewTag(label: string): void {
    const tag: SoulTag = new SoulTag();
    tag.label = label;
    this.appliedTags.push(tag);
  }

  private applyAvailableTag(label: string): void {
    const availableTag: SoulTag = this.availableTags.find(tag => tag.label === label);
    if (Objects.isNotNull(availableTag)) {
      this.appliedTags.push(availableTag);
      Arrays.removeElement(this.availableTags, availableTag);
    }
  }

  private moveFocus(index: number): void {
    index = Math.max(index, 0);
    if (this.shouldFocusSelect(index)) {
      this.focusSelect();
    } else {
      this.buttons.toArray()[index].nativeElement.focus();
    }
  }

  private focusSelect(): void {
    this.creatableSelect.input.nativeElement.focus();
  }

  private shouldFocusSelect(index: number): boolean {
    return index >= this.buttons.length;
  }

  private focusLastRemoveButton(): void {
    if (this.buttons) {
      this.moveFocus(this.buttons.length - 1);
    }
  }
}
