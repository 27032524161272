export class ValidationOutcome {

  private _message: string;
  private _valid: boolean;

  constructor(message: string, valid: boolean) {
    this._message = message;
    this._valid = valid;
  }

  public get message(): string {
    return this._message;
  }

  public set message(value: string) {
    this._message = value;
  }

  public get valid(): boolean {
    return this._valid;
  }

  public set valid(value: boolean) {
    this._valid = value;
  }
}
