import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {SelectComponent} from './select.component';

@Component({
  selector: 'soul-creatable-select',
  templateUrl: 'select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatableSelectComponent extends SelectComponent {

  @Output()
  public itemCreate: EventEmitter<any> = new EventEmitter();

  @ViewChild('input')
  public input: ElementRef;

  public stateless = true;
  public searchable = true;

  public noSelectAction(): void {
    if (this.filterTerm) {
      this.itemCreate.emit(this.filterTerm);
    }
    super.noSelectAction();
  }
}
