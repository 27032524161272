import { Component, Input } from '@angular/core';

@Component({
  selector: 'soul-button-dropdown',
  templateUrl: 'button-dropdown.component.html'
})
export class ButtonDropdownComponent {
  @Input()
  public enabled = true;
  @Input()
  public buttonType: string;
  @Input()
  public alignTo = 'left';

  public isDropdownVisible = false;

  public toggleDropdownVisibility(): void {
    if (this.enabled) {
      this.isDropdownVisible = !this.isDropdownVisible;
    }
  }

  public hideDropdown(): void {
    this.isDropdownVisible = false;
  }
}
