import {Pipe, PipeTransform} from '@angular/core';
import {Objects} from '../../utils/Objects';

@Pipe({name: 'soulBoxContentPipe'})
export class BoxContentPipe implements PipeTransform {

  private static readonly NO_VALUE = '-';

  public transform(value: string, pipe: PipeTransform): string {
    if (Objects.isNotDefined(value)) {
      return BoxContentPipe.NO_VALUE;
    }
    if (Objects.isDefined(pipe)) {
      return pipe.transform(value);
    }
    return value;
  }
}
