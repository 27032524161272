import {Directive, ElementRef, HostListener} from '@angular/core';
import {horizontalScroll} from './horizontalScroll';
import smoothscroll from 'smoothscroll-polyfill';

@Directive({
  selector: '[soulHorizontalScroll]'
})
export class HorizontalScrollDirective {

  constructor(private readonly element: ElementRef) {
    smoothscroll.polyfill();
  }

  @HostListener('wheel', ['$event'])
  public onWheel($event: WheelEvent): void {
    horizontalScroll($event, this.element.nativeElement);
  }
}
