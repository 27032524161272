import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[soulRepeat]'
})
export class RepeatDirective {

  constructor(private readonly templateRef: TemplateRef<any>,
              private readonly viewContainer: ViewContainerRef) {
  }

  @Input() set soulRepeat(times: number) {
    for (let i = 0; i < times; i++) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
