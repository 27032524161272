import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Column } from '../Column';
import { TemplateWrapperDirective } from '../../utils/template-wrapper.directive';

@Directive({
  selector: '[soulBoxContentTemplateWrapper]'
})
export class BoxContentTemplateWrapperDirective extends TemplateWrapperDirective {

  @Input()
  public soulBoxContentTemplateWrapper: TemplateRef<any>;
  @Input()
  public value: any;
  @Input()
  public item: any;
  @Input()
  public column: Column;

  constructor(viewContainer: ViewContainerRef) {
    super(viewContainer);
  }

  protected createEmbeddedViewContext(): any {
    return {
      value: this.value,
      column: this.column,
      item: this.item
    };
  }

  protected setEmbeddedViewRefValues(): void {
    this._embeddedViewRef.context.value = this.value;
    this._embeddedViewRef.context.column = this.column;
    this._embeddedViewRef.context.item = this.item;
  }

  protected getTemplateWrapper(): TemplateRef<any> {
    return this.soulBoxContentTemplateWrapper;
  }

  protected getTemplateWrapperName(): string {
    return 'soulBoxContentTemplateWrapper';
  }
}
