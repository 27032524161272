export class Objects {

  public static isDefined(obj: any): boolean {
    return typeof obj !== 'undefined';
  }

  public static isNotDefined(obj: any): boolean {
    return !Objects.isDefined(obj);
  }

  public static isNotNull(obj: any): boolean {
    return Objects.isDefined(obj) && (obj !== null);
  }

  public static isNull(obj: any): boolean {
    return obj === null;
  }
}
