import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OpenableDirective} from '../../modal-commons/openable.directive';

@Component({
  selector: 'soul-fullscreen-modal',
  templateUrl: 'fullscreen-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenModalComponent extends OpenableDirective {

  @Input()
  public backButton: boolean;

  @Input()
  public backLabel: string;

  @Input()
  public closeLabel: string;

  @Input()
  public section: string;

  @Input()
  public description: string;

  @Input()
  public scroll = true;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onBack: EventEmitter<void> = new EventEmitter<void>();

  public close(): void {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  public back(): void {
    this.onBack.emit();
  }
}
