import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MarkdownDisplayComponent } from './markdown-display/components/markdown-display.component';
import { TextboxComponent } from './textbox/components/textbox.component';
import { SearchboxComponent } from './searchbox/components/searchbox.component';
import { ExpanderComponent } from './expander/components/expander.component';
import { ModalComponent } from './modal/components/modal.component';
import { ButtonDropdownComponent } from './button-dropdown/components/button-dropdown.component';
import { ColumnHeaderComponent } from './column-header/column-header.component';
import { ToastNotificationListComponent } from './toast-notification-list/components/toast-notification-list.component';
import { TreeNavigationComponent } from './tree-navigation/components/tree-navigation.component';
import { TreeComponent } from './tree-navigation/components/tree.component';
import { EmptyStateComponent } from './empty-state/components/empty-state.component';
import { BoxContentComponent } from './box-content/components/box-content.component';
import { TextAreaComponent } from './textarea/components/textarea.component';
import { ToastNotificationComponent } from './toast-notification/components/toast-notification.component';
import { SpinnerComponent } from './spinner/components/spinner.component';
import { SoulTranslatePipe } from './pipes/translate.pipe';
import { ProgressBarComponent } from './progress-bar/components/progress-bar.component';
import { BoxContentPipe } from './box-content/pipes/box-content.pipe';
import { MarkDownFileService } from './markdown-display/services/markdown-file.service';
import { FileSizePipe } from './pipes/file-size.pipe';
import { HiddenOverrideDirective } from './attribute-directives/hidden-override.directive';
import { BoxContentTemplateWrapperDirective } from './box-content/attribute-directives/box-content-template-wrapper.directive';
import { TaglistEditorComponent } from './taglist-editor/components/taglist-editor.component';
import { TaglistEditorTemplateWrapperDirective } from './taglist-editor/attribute-directives/taglist-editor-template-wrapper.directive';
import { CardComponent } from './card/components/card.component';
import { ScrollOutsideDirective } from './attribute-directives/scroll-outside.directive';
import { StickToElementDirective } from './attribute-directives/stick-to-element.directive';
import { ClickCatcherComponent } from './click-catcher/components/click-catcher.component';
import { BackgroundImageDirective } from './attribute-directives/background-image.directive';
import { SelectComponent } from './select/components/select.component';
import { SelectContentDirective } from './select/directives/select-content.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from './avatar/components/avatar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SkeletonTableComponent } from './skeleton-table/components/skeleton-table.component';
import { RepeatDirective } from './attribute-directives/repeat.directive';
import { FullscreenModalComponent } from './fullscreen-modal/components/fullscreen-modal.component';
import { AnimationEndDirective } from './modal/directives/animation-end.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SoulHeightDirective } from './select/directives/soul-height.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CreatableSelectComponent } from './select/components/creatable-select.component';
import { TableSizesDirective } from './table/directives/table-sizes.directive';
import { ResizeObserverFactory } from './utils/resize-observer/ResizeObserverFactory';
import { HorizontalScrollDirective } from './tabs/horizontal-scroll.directive';

const EXPORTABLE_ELEMENTS: Type<any>[] = [
  AvatarComponent,
  SkeletonTableComponent,
  TextboxComponent,
  SearchboxComponent,
  ExpanderComponent,
  ModalComponent,
  FullscreenModalComponent,
  ButtonDropdownComponent,
  ProgressBarComponent,
  TreeNavigationComponent,
  TreeComponent,
  EmptyStateComponent,
  BoxContentComponent,
  TextAreaComponent,
  TaglistEditorComponent,
  ToastNotificationComponent,
  ToastNotificationListComponent,
  SpinnerComponent,
  MarkdownDisplayComponent,
  ClickCatcherComponent,
  SelectComponent,
  CardComponent,
  CreatableSelectComponent,

  SoulHeightDirective,
  SelectContentDirective,
  BoxContentTemplateWrapperDirective,
  TaglistEditorTemplateWrapperDirective,
  HiddenOverrideDirective,
  BackgroundImageDirective,
  RepeatDirective,
  ScrollOutsideDirective,
  StickToElementDirective,
  AnimationEndDirective,
  TableSizesDirective,
  HorizontalScrollDirective,
  SoulTranslatePipe,
  BoxContentPipe,
  FileSizePipe,
  SafeHtmlPipe
];

const NOT_EXPORTABLE_ELEMENTS: Type<any>[] = [
  ColumnHeaderComponent
];

@NgModule({
  declarations: [
    EXPORTABLE_ELEMENTS,
    NOT_EXPORTABLE_ELEMENTS
  ],
  exports: [
    EXPORTABLE_ELEMENTS
  ],
  providers: [
    MarkDownFileService,
    SafeHtmlPipe,
    {provide: ResizeObserverFactory, useClass: ResizeObserverFactory}
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ScrollingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SoulModule {
  public static forRoot(): ModuleWithProviders<SoulModule> {
    return {
      ngModule: SoulModule,
      providers: []
    };
  }
}
