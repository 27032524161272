import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Objects} from '../../utils/Objects';

@Component({
  selector: 'soul-progress-bar',
  templateUrl: 'progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input()
  public progressStatus: number;
  @Input()
  public label: string;
  @Input()
  public isWarning = false;
  @Input()
  public isCritical = false;

  public get isProgressAvailable(): boolean {
    return Objects.isNotNull(this.progressStatus);
  }
}
