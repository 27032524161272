import { Component, Input } from '@angular/core';

@Component({
  selector: 'soul-card',
  templateUrl: 'card.component.html'
})
export class CardComponent {
  @Input()
  public title: string;
  @Input()
  public imageUrl: string;
}
