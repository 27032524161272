import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'soul-skeleton-table',
  templateUrl: 'skeleton-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonTableComponent {
  @Input()
  public rows = 10;
  @Input()
  public columns = 1;
  @Input()
  public animated = true;
}
