import { TemplateRef, ComponentRef } from '@angular/core';
import { NotificationSeverities } from './NotificationSeverities';

export abstract class Notification {
  public title: string;
  public message?: string;
  public dismissible: boolean;
  public severity: NotificationSeverities;
  public template?: TemplateRef<any>;
  public component?: ComponentRef<any>;
}
