import { Objects } from './Objects';
import { KeyboardEvent } from './KeyboardEvent';

export class Events {

  public static stopEventPropagation(event: Event | KeyboardEvent): void {
    if (Objects.isNotNull(event)) {
      event.stopPropagation();
    }
  }
}
