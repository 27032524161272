import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SortState} from '../box-content/SortState';
import {Sort} from './Sort';
import {Column} from '../box-content/Column';

@Component({
  selector: 'soul-column-header',
  templateUrl: 'column-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnHeaderComponent {

  @Input()
  public column: Column;
  @Input()
  public sortState: SortState = SortState.NONE;

  @Output()
  public sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  public nextSort(): void {
    this.sortState = this.sortState.next();
    this.sortChange.emit(new Sort(this.column, this.sortState));
  }
}
